<template>
  <div class="py-6 relative">
    <input type="radio" id="unitex_invoice" class="mt-1" value="unitex_invoice"
           v-model="payment.paymentProvider">
    <label for="unitex_invoice" class="text-lg font-semibold pl-2">Unitex Zentralregulierung</label>
    <img :src="require('@/assets/images/unitex_logo.svg')" alt="Unitex Logo" class="h-12 pr-2 absolute right-0 top-3">
  </div>
  <div v-if="payment.paymentProvider === 'unitex_invoice'">
    <div>
      <input type="text" placeholder="Vertragsnummer" class="fmu-input" v-model="payment.unitex_invoice.contract_no">
    </div>
  </div>
</template>

<script>
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import {useSubscribeCourseModalStore} from "../../../../../store/modals/subscribe-course-modal-store";

export default {
  name: 'UnitexInvoice',
  props: ['isForCourse'],
  components: {},
  setup(props) {
    const newAboModalStore = useNewAboModalStore()
    const subscribeCourseStore = useSubscribeCourseModalStore();

    const payment = props.isForCourse ? subscribeCourseStore.payment : newAboModalStore.payment

    return {payment}
  }
}
</script>

<style scoped>
</style>
