<template>
  <div class="py-6 relative">
    <input type="radio" id="invoice" class="mt-1" value="invoice"
           v-model="payment.paymentProvider">
    <label for="invoice" class="text-lg font-semibold pl-2">Rechnung</label>
  </div>
</template>

<script>
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import {useSubscribeCourseModalStore} from "../../../../../store/modals/subscribe-course-modal-store";

export default {
  name: 'Invoice',
  props: ['isForCourse'],
  components: {},
  setup(props) {
    const newAboModalStore = useNewAboModalStore()
    const subscribeCourseStore = useSubscribeCourseModalStore();

    const payment = props.isForCourse ? subscribeCourseStore.payment : newAboModalStore.payment

    return {payment}
  }
}
</script>

<style scoped>
</style>
