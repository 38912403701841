<template>
  <div class="md:flex pt-20 xl:mx-0" id="filter">
    <div class="flex justify-between md:flex-none pb-2 px-4 lg:pr-4 lg:pl-0 xl:px-0">
      <el-select v-model="mediaFilterStore.primarySortingFilter" class="md:w-full" placeholder="Sortierung">
        <el-option
            v-for="item in mediaFilterStore.sorting"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      <fmu-button class="hover:bg-black hover:border-black md:hidden" style="padding:4px 10px"
                  :class="{'bg-black border-black': mediaFilterStore.secondaryFilterOpen, 'bg-bm-primary border-bm-primary': !mediaFilterStore.secondaryFilterOpen}"
                  @button-click="mediaFilterStore.toggleSecondaryFilter">
        <img :src="require('@/assets/icons/home/filter.svg')" style="height: 30px;">
      </fmu-button>
    </div>
    <categories class="md:hidden" v-if="subStore.subs.length > 0"></categories>
    <div class="justify-center w-full lg:pr-4 xl:pr-0 hidden md:flex">
      <div class="w-full relative" style="max-width: 700px">
        <img :src="require('@/assets/icons/home/suche.svg')" alt="" style="height: 22px; z-index: 1"
             class="absolute right-3 top-2 cursor-pointer">
        <el-autocomplete
            placeholder="Suchbegriff eingeben..."
            class="placeholder search-input w-full"
            v-model="search"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
        >
          <template #prepend>
            <div class="filter-abo">
              <el-select v-model="subOption" style="min-width: 140px"
                         @change="mediaFilterStore.changeSubOption">
                <el-option
                    v-for="(item, id) in subStore.getSubOptionsValues()"
                    :key="item.id"
                    :label="item.title"
                    :value="id">
                  <div :class="{'mt-3': id === 0}" class="filter-abo">
                    <p class="font-bold" v-if="id !== 0">Abo {{ id }}</p>
                    <span>{{ item.title }}</span>
                  </div>
                </el-option>
              </el-select>
            </div>
          </template>
        </el-autocomplete>
      </div>
    </div>
    <div class="flex justify-center w-full pt-2 md:hidden">
      <div class="relative w-full px-4">
        <img :src="require('@/assets/icons/home/suche.svg')" alt="" style="height: 21px; z-index: 1"
             class="absolute right-7 top-2 cursor-pointer">
        <el-autocomplete
            placeholder="Suchbegriff eingeben..."
            class="placeholder search-input w-full"
            v-model="search"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
        >
        </el-autocomplete>
      </div>
    </div>
    <div>
      <fmu-button class="hover:bg-black hover:border-black hidden md:block" style="padding:4px 10px"
                  :class="{'bg-black border-black': mediaFilterStore.secondaryFilterOpen, 'bg-bm-primary border-bm-primary': !mediaFilterStore.secondaryFilterOpen}"
                  @button-click="mediaFilterStore.toggleSecondaryFilter">
        <img :src="require('@/assets/icons/home/filter.svg')" style="height: 30px;">
      </fmu-button>
    </div>
  </div>
</template>

<script>
import {ElSelect, ElOption, ElAutocomplete} from 'element-plus'
import {useMediaFilterStore} from "@/store/media/media-filter-store";
import {ref, watch} from 'vue'
import {useUserStore} from "@/store/user/user-store";
import Categories from "@/views/home/media/filters/categories/Categories";

export default {
  name: 'MediaFilterPrimary',
  components: {
    ElAutocomplete,
    Categories,
    ElSelect,
    ElOption,
  },
  emits: ['button-click'],
  setup() {
    const mediaFilterStore = useMediaFilterStore()
    const userStore = useUserStore();
    const sortingFilter = ref(mediaFilterStore.sorting[0]);

    /**
     * watches for primary sorting changes
     * (sorting options for users & guests)
     **/
    watch(() => [
      userStore.user,
    ], () => {
      mediaFilterStore.setPrimaryFilters();
    });

    const disableSortingForGuest = (item) => {
      return !userStore.user.isLoggedIn && (item === 'Favoriten' || item === 'Downloads')
    }

    return {
      disableSortingForGuest,
      userStore,
      mediaFilterStore,
      sortingFilter
    }
  },
}
</script>

<script setup>
import {onMounted, ref} from "vue";
import {debouncedWatch} from "@vueuse/core/index";
import {useSubStore} from "../../../../store/subs/sub-store";
import FmuButton from "../../../../components/theme/button/FmuButton";
import emitter from "../../../../services/emitter";

const search = ref('');
const subOption = ref(0)

const mediaFilterStore = useMediaFilterStore()
const subStore = useSubStore();

onMounted(async () => {
  await mediaFilterStore.getAllFilter();
  await mediaFilterStore.getAllSuggestions();

  await subStore.getSubs()

  emitter.on('search-content', (e) => {
    window.scrollTo({top: document.getElementById('filter').offsetTop, behavior: 'smooth'});
    search.value = e.search;
  })

  emitter.on('change-sub-option', (e) => {
    subOption.value = e.sub;
  })

  await mediaFilterStore.setPrimaryFilters()
  if (!mediaFilterStore.category) {
    mediaFilterStore.selectCategory(null, true);
  }

  const selectFilter = (filter, selectFirstFilter) => {
    if (selectFirstFilter) {
      filter = mediaFilterStore.sorting[0] ?? '';
    }
    mediaFilterStore.setPrimarySortingFilter(filter);
  }

  if (!mediaFilterStore.primarySortingFilter) {
    selectFilter(null, true)
  }
})

const querySearch = (queryString, cb) => {
  var results = queryString
      ? mediaFilterStore.suggestions.filter(createFilter(queryString))
      : mediaFilterStore.suggestions
  cb(results);
};

const createFilter = (queryString) => {
  return (suggestions) => {
    return (
        suggestions.value.toLowerCase().includes(queryString.toLowerCase())
    );
  };
};

// search after 600 ms (debouncing for performance)
debouncedWatch(search, () => {
      mediaFilterStore.setSearchString(search.value)
    }, {debounce: 600}
)
</script>

<style scoped>
</style>
