<template>
  <div
      class="origin-top-right absolute md:right-3 md:w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 transform w-full"
      :class="{'opacity-0 scale-95': !navigationStore.dropdownOpen, 'transform opacity-100 scale-100': navigationStore.dropdownOpen}"
      role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
    <p class="block px-4 pt-2 font-bold text-black text-xl">{{ userStore.user.name }}</p>
    <span class="block px-4 font-thin text-xs">{{ userStore.user.email }}</span>
    <div class="px-4 py-2">
      <hr>
    </div>
    <a class="block px-4 text-sm text-gray-700 font-thin cursor-pointer" role="menuitem" @click="to('/einstellungen')">Kontoeinstellungen</a>
    <a class="block px-4 text-sm text-gray-700 font-thin cursor-pointer" role="menuitem" @click="toFaq">FAQ</a>
    <div class="px-4 py-2">
      <hr>
    </div>
    <a class="block px-4 text-sm text-gray-700 font-thin cursor-pointer" role="menuitem" @click="downloads">Downloads</a>
    <a class="block px-4 text-sm text-gray-700 font-thin cursor-pointer" role="menuitem" @click="favs">Favoriten</a>
    <div class="px-4 py-2">
      <hr>
    </div>
    <a href="#" class="block px-4 pb-2 text-sm text-gray-700 font-thin" role="menuitem" @click="userStore.logout()">Abmelden</a>
  </div>
</template>

<script>
import {useUserStore} from "@/store/user/user-store";
import {useNavigationStore} from "@/store/navigation/navigation-store";
import {useRouter} from "vue-router";
import {useMediaFilterStore} from "../../../../store/media/media-filter-store";

export default {
  name: 'DropdownMenu',
  components: {},
  setup() {
    const userStore = useUserStore();
    const navigationStore = useNavigationStore();
    const mediaFilterStore = useMediaFilterStore();

    const router = useRouter()

    const to = (route) => {
      navigationStore.toggleDropdown()
      router.push(route)
    }

    const downloads = () => {
      mediaFilterStore.setPrimarySortingFilter('Downloads')
      router.push({name: 'home', hash: '#filter'})
    }

    const favs = () => {
      mediaFilterStore.setPrimarySortingFilter('Favoriten')
      router.push({name: 'home', hash: '#filter'})
    }

    const toFaq = () => {
      navigationStore.toggleDropdown()
      router.push({name: 'pricing', hash: '#faq'})
    }

    return {userStore, navigationStore, to, downloads, favs, toFaq}
  }
}
</script>

<style scoped>
</style>
