<template>
  <div class="pb-28">
    <div v-if="newAboModalStore.sub.is_free">
      <free-sub></free-sub>
      <div class="fmu-error mx-24 my-2" v-if="errorMsg && !newAboModalStore.freeAboAccepted">
        {{ errorMsg }}
      </div>
    </div>
    <div v-else>
      <chargeable-sub></chargeable-sub>
    </div>
  </div>
  <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 flex justify-center">
    <fmu-button button-type="filled" @button-click="nextStep">
      In den Warenkorb
    </fmu-button>
  </div>
</template>

<script>
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import FmuButton from "@/components/theme/button/FmuButton";
import FreeSub from "@/components/modals/new_sub/content/subs/FreeSub";
import ChargeableSub from "@/components/modals/new_sub/content/subs/ChargeableSub";
import {onMounted, ref} from "vue"
import {useBasketStore} from "@/store/basket/basket-store";
import {basketStep} from "@/configurations/payment/payment-config";

export default {
  name: 'SubContent',
  components: {ChargeableSub, FreeSub, FmuButton},
  emits: ['button-click'],
  setup() {
    const newAboModalStore = useNewAboModalStore();
    const basketStore = useBasketStore();
    const errorMsg = ref('')

    onMounted(() => {
      newAboModalStore.prepareFormLoggedInUser();
    })

    const nextStep = () => {
      if (newAboModalStore.sub.is_free && !newAboModalStore.freeAboAccepted) {
        errorMsg.value = 'Um fortfahren zu können, musst Du akzeptieren'
      } else {
        basketStore.addToBasket(newAboModalStore.sub)
        newAboModalStore.goToStep(basketStep)
      }
    }

    return {
      nextStep,
      errorMsg,
      newAboModalStore
    }
  }
}
</script>

<style scoped>
</style>
