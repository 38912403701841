<template>
  <div class="flex justify-center text-white" v-if="!userStore.user.newsletter_accepted"
       :style="{ backgroundImage: 'url(\'' + require('@/assets/images/home/newsletter_bg.png') + '\')' }"
       style="background-repeat: no-repeat; background-size: cover">
    <div class="md:py-16 py-10 bg-no-repeat bg-newsletter h-full">
      <div class="fmu-container text-center px-5 md:px-0">
        <p class="text-4xl pb-1 text-headline font-condensed font-bold uppercase" style="line-height: 1.2">Lass dich inspirieren...</p>
        <p class="text-2xl md:text-subline lg:mx-12 xl:mx-0">
          und erhalte immer die aktuellen Aktionen & Updates mit unserem Newsletter!
        </p>
        <div class="py-9">
          <input type="text" style="height: 50px"
                 class="md:w-102 px-2 w-52 text-text focus:outline-none border border-gray-400 border-r-0 rounded rounded-r-none text-black"
                 placeholder="Gebe Deine E-Mail Adresse ein..."
                 :class="{'border border-red-500 border-r-0': errorMail}" v-model="email">
          <fmu-button class="py-3 border-l-0 rounded-l-none" @button-click="subscribe">Anmelden
          </fmu-button>
        </div>
<!--        <div class="flex pt-5 newsletter-checkbox text-left md:px-56 hidden md:block">-->
<!--          <el-checkbox v-model="confirmed">-->
<!--          <span :class="{'text-red-500': errorConfirmed}" class="break-normal">-->
<!--              Ja, ich will die Inspirations-Mails erhalten. Dem kann ich jederzeit bei der Hutter & Unger GmbH widersprechen.-->
<!--          </span>-->
<!--          </el-checkbox>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import FmuButton from "@/components/theme/button/FmuButton";
// import {ElCheckbox} from "element-plus"
import axios from "axios";
import {ref} from "vue"
import {useNotifyStore} from "@/store/notify/notify-store";
import {mailIsValid} from "@/validators/validators";
import {ElLoading} from "element-plus";
import {useUserStore} from "@/store/user/user-store";

export default {
  name: 'Newsletter',
  components: {
    FmuButton,
    // ElCheckbox
  },
  emits: ['button-click'],
  setup() {
    const email = ref('');
    const confirmed = ref(false);
    const notifyStore = useNotifyStore();
    const errorConfirmed = ref(false)
    const errorMail = ref(false)

    const userStore = useUserStore();

    const subscribe = () => {
      errorMail.value = !email.value || !mailIsValid(email.value);
      errorConfirmed.value = !confirmed.value;

      if (email.value && mailIsValid(email.value)) {
        let loading = ElLoading.service({fullscreen: true})
        axios.get(`newsletter/subscribe/${email.value}`).then(() => {
          notifyStore.notify('Erfolgreich', 'Du hast dich für den Newsletter angemeldet.')
          email.value = '';
          confirmed.value = false;
          loading.close();
        }).catch(() => {
          loading.close()
        })
      }
    }

    return {subscribe, confirmed, email, errorConfirmed, errorMail, userStore}
  }
}
</script>

<style scoped>
</style>
