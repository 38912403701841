export const baseUrl = process.env.VUE_APP_URL;

/**
 * Do not change order of array
 * @type {string[]}
 */
export const sortingFilterUser = [
    'Alle',
    'Neueste',
    'Meine Downloads',
    'Meine Favoriten',
]

export const sortingFilterGuest = [
    'Alle',
    'Neueste',
]

