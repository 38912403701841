import {defineStore} from 'pinia'
import axios from "axios";
import {useLoginModalStore} from "@/store/modals/login-modal";
import {useUserStore} from "@/store/user/user-store";
import {ElLoading} from "element-plus";
import {isEmpty} from "@/validators/validators";
import {useSubscribeCourseModalStore} from "@/store/modals/subscribe-course-modal-store";
import {useBasketStore} from "@/store/basket/basket-store";
import {paymentProviderStep, subAlreadyBookedStep} from "../../configurations/payment/payment-config";

export const useNewAboModalStore = defineStore({
    id: 'newAboModal',
    state: () => ({
        isOpen: false,
        closable: true,
        sub: [],
        step: 1,
        form: {
            company: '',
            first_name: '',
            last_name: '',
            street: '',
            house_number: '',
            postal_code: '',
            city: '',
            email: '',
            newsletter: false,
            phone: '',
            country: 'DE',
            ustid: ''
        },
        payment: {
            paymentProvider: 'credit_card',
            creditCard: {
                cardtype: 'V',
                cardpan: '',
                cardcvc2: '',
                expireMonth: '',
                expireYear: '',
                first_name: '',
                last_name: ''
            },
            debit: {
                iban: '',
                bic: '',
                holder: ''
            },
            unitex_invoice: {
                contract_no: ''
            }
        },
        userId: null,
        freeAboAccepted: false,
        errorMsg: '',
        mailExists: false,
        addressExists: false,
        subsAlreadyBooked: []
    }),
    getters: {},
    actions: {
        disableClosable() {
            this.closable = false;
        },
        newAbo(sub) {
            this.step = 'subContent';
            this.sub = sub;
            this.isOpen = !this.isOpen
        },
        goToStep(step) {
            const userStore = useUserStore();

            if (step === paymentProviderStep) {
                if (this.form.email) {
                    this.checkIfEmailExists(this.form.email);
                } else if (userStore.user.isLoggedIn) {
                    this.checkIfEmailExists(userStore.user.email)
                }
            }

            this.step = step
        },
        toggle() {
            this.isOpen = !this.isOpen;
        },

        /**
         * create new subscription
         */
        subscribe() {
            this.setErrorMsg('')
            const basketStore = useBasketStore()
            const userStore = useUserStore()

            let loading = ElLoading.service({fullscreen: true})
            axios.post('user/subscribe', {
                form: this.form,
                user_id: userStore.user.id ?? this.userId ?? null,
                basket: basketStore.basket,
                payment: this.payment,
                address_exists: this.addressExists
            }).then(async (res) => {
                let response = res.data;
                if (response.status === 'REDIRECT') {
                    window.location.replace(response.redirecturl);
                    localStorage.removeItem('basket')
                } else if (response.status === 'APPROVED') {
                    window.location.replace(`/success/${response.payment_token}`);
                    localStorage.removeItem('basket')
                } else if (response.status === 'ERROR') {
                    window.location.replace(`/error/${response.payment_token}`);
                } else {
                    this.errorMsg = 'Ein Fehler ist aufgetreten. Versuche es noch einmal.';
                }

                loading.close();
            }).catch((error) => {
                if (error.response.data.error_message) {
                    this.errorMsg = error.response.data.error_message;
                } else {
                    this.errorMsg = 'Ein Fehler ist aufgetreten. Versuche es noch einmal.';
                }

                loading.close()
            })
        },
        async loginAfterSubscription() {
            const loginModalStore = useLoginModalStore();
            const userStore = useUserStore();

            await loginModalStore.setLoginForm(this.form)
            userStore.login()
            this.resetForm();
        },
        resetForm() {
            Object.keys(this.form).forEach(key => {
                this.form[key] = ''
            });
        },
        setErrorMsg(msg) {
            this.errorMsg = msg;
        },
        goBack() {
            this.goToStep(this.step - 1);
            this.setErrorMsg('')
        },
        checkIfEmailExists(mail) {
            const basketStore = useBasketStore()

            let loading = ElLoading.service({fullscreen: true})
            axios.post('user/check-email', {
                email: mail,
                basket: basketStore.basket.subs
            }).then((res) => {
                this.userId = res.data.user_id;
                this.mailExists = res.data.email_exists;
                this.addressExists = res.data.address_exists;
                this.subsAlreadyBooked = res.data.subs_already_booked;

                if (res.data.subs_already_booked.length > 0) {
                    this.goToStep(subAlreadyBookedStep)
                }

                loading.close()
            }).catch(() => {
                loading.close()
            })
        },
        isCreditCard() {
            return this.payment.paymentProvider === 'credit_card';
        },
        isDebit() {
            return this.payment.paymentProvider === 'debit';
        },
        resetCreditCard() {
            Object.keys(this.payment.creditCard).forEach(key => {
                if (key !== 'cardtype') {
                    this.payment.creditCard[key] = ''
                }
            })
        },
        fieldsAreEmpty(isForCourse) {
            const newAboModalStore = useNewAboModalStore();
            const subscribeCourseStore = useSubscribeCourseModalStore();

            const payment = isForCourse ? subscribeCourseStore.payment : newAboModalStore.payment;

            return (payment.paymentProvider === 'credit_card' && isEmpty(payment.creditCard))
                || (payment.paymentProvider === 'debit' && isEmpty(payment.debit))
                || (payment.paymentProvider === 'unitex_invoice' && isEmpty(payment.unitex_invoice));
        },
        prepareFormLoggedInUser() {
            const userStore = useUserStore()
            if (userStore.user.isLoggedIn) {
                this.userId = userStore.user.id
            }
        },
    },
})
