import { defineStore } from 'pinia'

export const useChooseAboNotificationModalStore = defineStore({
    id: 'chooseAboNotificationModalStore',
    state: () => ({
        isOpen: false,
    }),
    getters: {},
    actions: {
        toggleModal() {
            this.isOpen = !this.isOpen
        },
    },
})
