<template>
  <div class="fixed inset-0 flex px-4 py-6 pointer-events-none p-6 items-start justify-end opacity-0" style="z-index: 99999" :class="notifyStore.class">
    <div class="max-w-sm w-full">
      <div class="flex max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden mt-4">
        <div class="flex justify-center items-center w-12" :class="{'bg-green-500': notifyStore.type === 'success', 'bg-red-500': notifyStore.type === 'error'}">
          <img class="h-6 w-6 fill-current text-white" :src="require('@/assets/icons/notify_check.svg')">
        </div>

        <div class="-mx-3 py-2 px-4">
          <div class="mx-3">
            <span class="font-semibold" :class="{'text-green-500': notifyStore.type === 'success', 'text-red-500': notifyStore.type === 'error'}">{{ notifyStore.title }}</span>
            <p class="text-gray-600 text-sm">{{ notifyStore.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useNotifyStore} from "@/store/notify/notify-store";

export default {
  name: 'Notify',
  components: {},
  setup() {
    const notifyStore = useNotifyStore();
    return {notifyStore}
  }
}
</script>

<style scoped>
.fade-in {
  animation: fadeIn ease 1s forwards;
  -webkit-animation: fadeIn ease 1s forwards;
  -moz-animation: fadeIn ease 1s forwards;
  -o-animation: fadeIn ease 1s forwards;
  -ms-animation: fadeIn ease 1s forwards;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-out {
  animation: fadeOut ease 1s forwards;
  -webkit-animation: fadeOut ease 1s forwards;
  -moz-animation: fadeOut ease 1s forwards;
  -o-animation: fadeOut ease 1s forwards;
  -ms-animation: fadeOut ease 1s forwards;
}
@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
