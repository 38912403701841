<template>
  <div class="pb-36 curso">
    <div class="flex justify-center">
      <img :src="require('@/assets/icons/check-cirlce.svg')" alt="zum Warenkorb hinzugefügt" class="h-16">
    </div>
    <p class="modal-headline text-center md:px-24 pb-8 pt-4 break-normal text-black">
      Abo wurde dem Warenkorb hinzugefügt!
    </p>
    <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 flex justify-center">
      <div class="text-center block" v-if="basketStore.basket.subs.length > 0">
        <fmu-button button-type="filled" @button-click="nextStep" class="mb-4">
          Warenkorb anzeigen
          <img :src="require('@/assets/icons/chevron_right.svg')" alt="" class="h-5 pl-1 float-right">
        </fmu-button>
        <br>
        <a class="" href="#" @click="newAboModalStore.toggle">Weitere Abos auswählen</a>
      </div>
    </div>
  </div>
</template>

<script>
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import FmuButton from "@/components/theme/button/FmuButton";
import {basketStep} from "@/configurations/payment/payment-config";
import {useBasketStore} from "@/store/basket/basket-store";

export default {
  name: 'AddedToBasket',
  components: {FmuButton},
  setup() {
    const newAboModalStore = useNewAboModalStore();
    const basketStore = useBasketStore();

    const nextStep = () => {
      newAboModalStore.goToStep(basketStep)
    }

    return {
      basketStore,
      nextStep,
      newAboModalStore
    }
  },
}
</script>

<style scoped>
</style>
