import {defineStore} from 'pinia'
import axios from 'axios'
import {useNotifyStore} from "@/store/notify/notify-store";
import {ElLoading} from "element-plus";
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import {registerStep} from "@/configurations/payment/payment-config";

export const useSubscribeCourseModalStore = defineStore({
    id: 'subscribeCourseModal',
    state: () => ({
        isOpen: false,
        course: null,
        form: {
            first_name: '',
            last_name: '',
            street: '',
            house_number: '',
            postal_code: '',
            city: '',
            email: '',
            country: 'DE'
        },
        payment: {
            paymentProvider: 'credit_card',
            creditCard: {
                cardtype: 'V',
                cardpan: '',
                cardcvc2: '',
                expireMonth: '',
                expireYear: '',
                first_name: '',
                last_name: ''
            },
            debit: {
                iban: '',
                bic: ''
            },
            unitex_invoice: {
                contract_no: ''
            }
        },
        error: ''
    }),
    getters: {},
    actions: {
        open(course) {
            const newAboModalStore = useNewAboModalStore();
            newAboModalStore.step = registerStep;

            this.course = course;
            this.isOpen = true;
        },
        resetCreditCard() {
            Object.keys(this.payment.creditCard).forEach(key => {
                if (key !== 'cardtype') {
                    this.payment.creditCard[key] = ''
                }
            })
        },
        bookCourse() {
            const notifyStore = useNotifyStore();
            const newAboModalStore = useNewAboModalStore();

            let loading = ElLoading.service({fullscreen: true})

            axios.post('courses/registration', {
                form: this.form,
                course: this.course,
                payment: this.payment
            }).then((res) => {
                loading.close();
                let response = res.data;
                if (response.user_error_message) {
                    this.error = response.user_error_message;
                } else if (response.status === 'REDIRECT') {
                    window.location.replace(response.redirecturl);
                } else if (response.status === 'APPROVED') {
                    this.isOpen = false;
                    notifyStore.notify('ERFOLGREICH', 'Für ein Onlineseminar erfolgreich angemeldet')
                    loading.close()
                } else {
                    newAboModalStore.errorMsg = 'Ein Fehler ist aufgetreten. Versuche es noch einmal.';
                }
            }).catch(() => {
                loading.close();
            })
        },
    },
})
