<template>
  <div class="newAboModal">
    <el-dialog
        title=""
        v-model="newAboModalStore.isOpen"
        :close-on-click-modal="0"
        :close-on-press-escape="1"
        :show-close="newAboModalStore.closable"
        :fullscreen="widthIs('<', 700)"
        width="800px"
        top="7%"
        custom-class="relative">
      <component :is="newAboModalStore.step"></component>
    </el-dialog>
  </div>
</template>

<script>
import {ElDialog} from 'element-plus'
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import RegisterContent from "@/components/modals/new_sub/content/RegisterContent";
import PaymentProviderContent from "@/components/modals/new_sub/content/PaymentProviderContent";
import SubBookedContent from "@/components/modals/new_sub/content/SubBookedContent";
import {
  subContentStep,
  paymentProviderStep,
  registerStep,
  subBookedStep,
  basketStep
} from "@/configurations/payment/payment-config";
import BasketContent from "@/components/modals/new_sub/content/BasketContent";
import SubContent from "@/components/modals/new_sub/content/SubContent";
import AddedToBasket from "@/components/modals/new_sub/content/AddedToBasket";
import SubAlreadyBookedContent from "./content/SubAlreadyBookedContent";
import widthIs from "../../../validators/validators";

export default {
  name: 'NewSubModal',
  components: {
    SubContent,
    BasketContent,
    SubBookedContent,
    PaymentProviderContent,
    RegisterContent,
    AddedToBasket,
    SubAlreadyBookedContent,
    ElDialog,
  },
  setup() {
    const newAboModalStore = useNewAboModalStore();
    return {
      newAboModalStore,
      subContentStep,
      registerStep,
      paymentProviderStep,
      subBookedStep,
      basketStep,
      widthIs
    }
  }
}
</script>

<style>
@media only screen and (min-width: 700px) {
  .newAboModal > .el-overlay > .el-dialog > .el-dialog__body {
    padding: 0 !important;
  }

  .newAboModal > .el-overlay > .el-dialog {
    height: auto;
  }
}
</style>
<style scoped>
</style>
