import {defineStore} from 'pinia'
import Cookies from "js-cookie";

export const useCookieConsentStore = defineStore({
    id: 'cookieConsentStore',
    state: () => ({
        isOpen: false,
    }),
    getters: {},
    actions: {
        toggle() {
            this.isOpen = !this.isOpen;
        },
        checkForCookie() {
            this.isOpen = !Cookies.get('cookies-accepted');
        }
    },
})
