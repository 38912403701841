<template>
  <div class="pb-36 relative">
    <p class="modal-headline text-center px-24 font-semibold break-normal"
       v-if="!$props.isForCourse">
      Bestellung
    </p>
    <div class="font-thin pb-4 pt-2 text-xs text-center break-normal px-10">
      Dieses Angebot richtet sich ausschließlich an gewerbliche Kunden!
    </div>
    <p class="font-serif text-25px text-center md:px-24 font-semibold break-normal" v-if="$props.isForCourse">
      Für "{{ subscribeCourseModalStore.course.course_name }}" anmelden
    </p>

    <div class="md:px-24 pt-5">
      <div v-if="errorMsg" class="fmu-error mb-3">
        {{ getErrorMsg() }}
      </div>
      <div v-if="subscribeCourseModalStore.error" class="fmu-error mb-3">
        {{ subscribeCourseModalStore.error }}
      </div>
      <small class="text-red-500" v-if="inputError.email">Ungültige E-Mail-Adresse</small>
      <input type="email" class="fmu-input mb-2"
             placeholder="E-Mail" name="email" v-model="form.email" @focusout="checkInputError"
             v-if="!$props.isForCourse" :class="{'border-red-500': inputError.email}">
      <input type="email" class="fmu-input mb-2"
             @focusout="checkInputError"
             placeholder="E-Mail" v-model="form.email" :class="{'border-red-500': inputError.email}" name="email"
             v-else>
<!--      <div v-if="newAboModalStore.mailExists && !$props.isForCourse" class="fmu-success">-->
<!--        Diese E-Mail ist bereits im smart.KIS registriert. <br>Du kannst Dich später mit dem smart.KIS-Passwort-->
<!--        anmelden.-->
<!--      </div>-->
      <br>
      <div v-if="!newAboModalStore.addressExists">
        <input type="text" class="fmu-input mb-2"
               @focusout="checkInputError"
               name="company"
               placeholder="Firma" v-model="form.company" :class="{'border-red-500': inputError.company}">
        <div class="grid grid-cols-2 gap-2">
          <input type="text" class="fmu-input mb-2"
                 @focusout="checkInputError"
                 placeholder="Vorname" v-model="form.first_name" name="first_name"
                 :class="{'border-red-500': inputError.first_name}">
          <input type="text" class="fmu-input mb-2"
                 @focusout="checkInputError"
                 placeholder="Nachname" v-model="form.last_name" name="last_name"
                 :class="{'border-red-500': inputError.last_name}">
        </div>
        <div class="flex w-full">
          <div class="w-3/4 mr-2">
            <input type="text" class="fmu-input mb-2 col-span-7"
                   @focusout="checkInputError"
                   placeholder="Straße" v-model="form.street" name="street"
                   :class="{'border-red-500': inputError.street}">
          </div>
          <div class="w-1/4">
            <input type="text" class="fmu-input mb-2"
                   @focusout="checkInputError"
                   placeholder="Hausnummer" v-model="form.house_number" name="house_number"
                   :class="{'border-red-500': inputError.house_number}">
          </div>
        </div>
        <div class="flex w-full">
          <div class="w-1/4 mr-2">
            <input type="text" class="fmu-input mb-2"
                   @focusout="checkInputError"
                   placeholder="PLZ" v-model="form.postal_code" v-mask="'#####'" name="postal_code"
                   :class="{'border-red-500': inputError.postal_code}">
          </div>
          <div class="w-3/4">
            <input type="text" class="fmu-input mb-2"
                   @focusout="checkInputError"
                   placeholder="Stadt" v-model="form.city" name="city" :class="{'border-red-500': inputError.city}">
          </div>
        </div>
        <select class="fmu-select mb-2" v-model="form.country" v-if="!$props.isForCourse">
          <option value="DE" selected>Deutschland</option>
          <option value="AT">Österreich</option>
          <option value="CH">Schweiz</option>
        </select>
        <input type="text" class="fmu-input mb-2"
               placeholder="Ust-ID" v-model="form.ustid"
               @focusout="checkInputError"
               v-if="!$props.isForCourse && form.country !== 'DE'" name="ustid"
               :class="{'border-red-500': inputError.ustid}">
        <input type="tel" class="fmu-input mb-2"
               @focusout="checkInputError"
               placeholder="Telefon" v-model="form.phone" @keypress="isNumeric($event)" name="phone"
               :class="{'border-red-500': inputError.phone}">

      </div>
<!--      <div v-if="!newAboModalStore.mailExists && !$props.isForCourse">-->
<!--        <input type="password" class="fmu-input mb-2"-->
<!--               @focusout="checkInputError"-->
<!--               placeholder="Passwort" v-model="form.password" v-if="!$props.isForCourse"-->
<!--               @input="checkPassword(form, passwordValid)"-->
<!--               name="password" :class="{'border-red-500': inputError.password}">-->
<!--        <input type="password" class="fmu-input mb-2"-->
<!--               @focusout="checkInputError"-->
<!--               placeholder="Passwort wiederholen" v-model="form.passwordRepeat" v-if="!$props.isForCourse"-->
<!--               name="passwordRepeat" :class="{'border-red-500': inputError.passwordRepeat}">-->
<!--        <div class="py-5 text-xs" v-if="form.password != ''">-->
<!--          <progress :value="progress" max="100" class="mb-2 w-full"></progress>-->
<!--          <ul class="list-disc pl-5 font-thin">-->
<!--            <li :class="{ 'line-through': containsSevenCharacters }">7 Zeichen</li>-->
<!--            <li :class="{ 'line-through': containsNumber }">Enthält Zahlen</li>-->
<!--            <li :class="{ 'line-through': containsSpecialCharacter }">Enthält Sonderzeichen</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
      <!--      <div class="flex pt-2 newsletter-checkbox" v-if="!$props.isForCourse">-->
      <!--        <el-checkbox v-model="form.newsletter">-->
      <!--          Ja, ich will die Inspirations-Mails des Basic Marketing Newsletters erhalten. <br> Dem kann ich jederzeit bei-->
      <!--          der Hutter & Unger GmbH-->
      <!--          widersprechen.-->
      <!--        </el-checkbox>-->
      <!--      </div>-->
      <div class="font-thin py-5">
        Alle Felder sind Pflichtfelder
      </div>
      <p class="pt-5 font-thin break-normal" v-if="$props.isForCourse">
        Mit dem Drücken des Buttons "Hiermit verbindlich anmelden!" wird Deine Anmeldung versendet. Du erhältst von uns
        per eMail eine Anmeldungsbestätigung sowie die weiteren Schritte zur Zahlung.
      </p>
    </div>

<!--    DEKTOP-->
    <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 md:flex justify-center hidden">
      <fmu-button button-type="outline" type="button" @button-click="newAboModalStore.goToStep(basketStep)" class="mr-4"
                  v-if="!$props.isForCourse">
        zurück
      </fmu-button>
      <fmu-button button-type="filled" type="submit" @button-click="process">
        <span>Weiter zu Zahlungsart wählen</span>
        <img :src="require('@/assets/icons/chevron_right.svg')" alt="" class="h-5 pl-1 float-right">
      </fmu-button>
    </div>

<!--    MOBILE-->
    <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 md:hidden justify-center px-10">
      <fmu-button button-type="filled" type="submit" @button-click="process" class="w-full">
        <span>Weiter zu Zahlungsart wählen</span>
        <img :src="require('@/assets/icons/chevron_right.svg')" alt="" class="h-5 pl-1 float-right">
      </fmu-button>
      <fmu-button button-type="outline" type="button" @button-click="newAboModalStore.goToStep(basketStep)"
                  class="mr-4 w-full mt-2"
                  v-if="!$props.isForCourse">
        zurück
      </fmu-button>
    </div>
  </div>
</template>

<script>
import FmuButton from "@/components/theme/button/FmuButton";
import {mask} from 'vue-the-mask'

export default {
  name: 'RegisterContent',
  components: {
    FmuButton,
    // ElCheckbox
  },
  directives: {mask},
  emits: ['button-click'],
  methods: {}
}
</script>

<script setup>
import {defineProps, ref} from "vue";
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
// import {ElCheckbox} from "element-plus"
import {useSubscribeCourseModalStore} from "@/store/modals/subscribe-course-modal-store";
import {
  mailIsValid,
  emailErrorMsg,
  valuesAreEqual,
  isEmpty,
  emptyErrorMsg,
  passwordsAreNotEqualErrorMsg
} from "@/validators/validators";
import {isNumeric} from "@/filter/isNumeric";
import {useUserStore} from "@/store/user/user-store";
import {basketStep, paymentProviderStep} from "@/configurations/payment/payment-config";
import {passwordInvalid} from "../../../../validators/validators";
import {
  checkPassword,
  passwordValid,
  containsSevenCharacters,
  containsNumber,
  containsSpecialCharacter,
  progress,
  progressColor
} from "../../../../services/register/passwortChecker";

const props = defineProps({
  isForCourse: {
    type: Boolean,
    default: false
  }
})

const newAboModalStore = useNewAboModalStore();
const subscribeCourseModalStore = useSubscribeCourseModalStore();
const userStore = useUserStore()

const inputError = ref({
  email: false,
  company: false,
  first_name: false,
  last_name: false,
  street: false,
  house_number: false,
  postal_code: false,
  city: false,
  ustid: false,
  phone: false,
});

let errorMsg = ref('')
const form = props.isForCourse ? subscribeCourseModalStore.form : newAboModalStore.form

const errorMsgIsEmpty = () => {
  return errorMsg.value === ''
}

const checkInputError = (event) => {
  inputError.value[event.target.name] = form[event.target.name] == '' || form[event.target.name] == null;

  if (event.target.name === 'email') {
    // if (!props.isForCourse) {
    //   newAboModalStore.checkIfEmailExists(form.email)
    // }

    inputError.value['email'] = !mailIsValid(form.email)
  }
}

// if (!props.isForCourse) {
//   checkPassword(form, passwordValid);
// }

const getErrorMsg = () => {
  const except = [];

  if (props.isForCourse) {
    except.push('country', 'ustid')
  }

  if (form.country === 'DE') {
    except.push('ustid')
  }

  if (!newAboModalStore.addressExists) {
    if (isEmpty(form, except)) {
      errorMsg.value = emptyErrorMsg
    } else if (!valuesAreEqual(form.password, form.passwordRepeat)) {
      errorMsg.value = passwordsAreNotEqualErrorMsg
    } else if (!mailIsValid(form.email)) {
      errorMsg.value = emailErrorMsg
    } else if (!passwordValid.value) {
      errorMsg.value = passwordInvalid
    } else {
      errorMsg.value = ''
    }
    return errorMsg.value;
  }
}

const process = () => {
  getErrorMsg()
  if (errorMsgIsEmpty()) {
    if (props.isForCourse) {
      subscribeCourseModalStore.bookCourse()
    } else {
      newAboModalStore.goToStep(paymentProviderStep);
    }
  }
}
</script>

<style>
@media only screen and (min-width: 700px) {

  .newAboModal > .el-overlay > .el-dialog > .el-dialog__body {
    padding: 0 !important;
  }

  .newAboModal > .el-overlay > .el-dialog {
    height: 400px;
  }

  .el-radio-button__inner {
    padding: 8px 20px !important;
  }
}
</style>
<style scoped>
progress[value]::-webkit-progress-value {
  background-color: v-bind('progressColor');
}
</style>
