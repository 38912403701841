import {defineStore} from 'pinia'
import {useNotifyStore} from "@/store/notify/notify-store";
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import axios from "axios";
import {addedToBasket} from "@/configurations/payment/payment-config";

export const useBasketStore = defineStore({
    id: 'basketStore',
    state: () => ({
        couponCode: '',
        basket: {
            coupon: null,
            period: 'yearly',
            subs: []
        },
        ids: [],
        modalIsOpen: false,
        errorMsg: '',
        periodSwitcher: false
    }),
    getters: {
        basketPeriod() {
            return this.basket.period;
        },
    },
    actions: {
        addToBasket(item) {
            const newAboStore = useNewAboModalStore()

            this.basket.subs.push(item);
            this.ids.push(item.id)
            this.setCookies();

            if (this.basket.coupon) {
                this.checkCoupon(false);
            }

            this.basket.period = this.periodSwitcher ? 'monthly' : 'yearly';

            newAboStore.toggle();
            newAboStore.goToStep(addedToBasket)
        },
        initBasket() {
            const basket = localStorage.getItem('basket');
            if (basket) {
                this.basket = JSON.parse(basket ?? '');
                this.idsPush()
            }
        },
        idsPush() {
            this.ids = [];
            this.basket.subs.forEach(item => {
                this.ids.push(item.id)
            })
        },
        async removeFromBasket(index) {
            await this.basket.subs.splice(index, 1);
            this.setCookies();

            if (this.basket.coupon) {
                this.checkCoupon(false);
            }
        },
        setCookies() {
            localStorage.removeItem('basket')
            localStorage.setItem('basket', JSON.stringify(this.basket))
        },
        checkCoupon(notify = true) {
            const newAboStore = useNewAboModalStore();
            const notifyStore = useNotifyStore();

            if (this.basket.coupon) {
                this.couponCode = this.basket.coupon.coupon_code;
            }

            axios.post(`coupon/check`, {
                coupon_code: this.couponCode,
                basket: this.basket,
                user_id: newAboStore.userId
            }).then(res => {
                if (res.data.status === 'error') {
                    this.errorMsg = res.data.message;
                } else if (!res.data.coupon_redeemable) {
                    this.errorMsg = 'Du hast den Gutschein bereits eingelöst'
                } else {
                    this.basket.coupon = res.data;
                    this.setCookies();

                    this.errorMsg = ''

                    if (notify) {
                        notifyStore.notify('Erfolgreich', 'Gutschein eingelöst')
                    }
                }
            })
        },
        removeCoupon() {
            this.couponCode = null;
            this.basket.coupon = null;

            this.setCookies();
        },
    }
})
