<template>
  <button :type="$props.type" class="border-fmu-fontcolor border text-center px-6 py-2 rounded
  hover:border-black font-thin transition duration-500 hover:text-black focus:outline-none "
          :class="$props.class" v-if="$props.buttonType === 'outline'" :disabled="$props.disabled"
          @click="$emit('button-click')"
          :style="$props.style" >
    <slot>Anmelden</slot>
  </button>

  <button :type="$props.type" class="border border-fmu-gray-1 text-center px-6 py-1 rounded
  font-thin transition duration-500 hover:text-black hover:bg-fmu-secondary focus:outline-none align-text-bottom disabled:opacity-50 text-15px"
          :class="$props.class" v-if="$props.buttonType === 'outline-light'" :disabled="$props.disabled"
          @click="$emit('button-click')"
          :style="$props.style" >
    <slot>Anmelden</slot>
  </button>

  <button
      :type="$props.type"
      class="bg-black text-white px-6 py-2 rounded border border-black
      font-thin transition duration-500 hover:bg-fmu-fontcolor
      focus:outline-none disabled:opacity-50" :style="$props.style" :class="$props.class" v-if="$props.buttonType === 'filled'" :disabled="$props.disabled"
      @click="$emit('button-click')">
    <slot>Registrieren</slot>
  </button>

  <button
      :type="$props.type"
      class="bg-bm-primary text-white px-6 py-2 rounded border border-bm-primary
      font-thin transition duration-500 hover:bg-fmu-fontcolor hover:border-fmu-fontcolor
      focus:outline-none disabled:opacity-50" :style="$props.style" :class="$props.class" v-if="$props.buttonType === 'primary'" :disabled="$props.disabled"
      @click="$emit('button-click')">
    <slot>Registrieren</slot>
  </button>

  <button
      :type="$props.type"
      class="bg-bm-font-gray-4 px-6 py-2 rounded border border-bm-font-gray-4
      text-black
      font-thin transition duration-500
      focus:outline-none disabled:opacity-50" :style="$props.style" :class="$props.class" v-if="$props.buttonType === 'secondary'" :disabled="$props.disabled"
      @click="$emit('button-click')">
    <slot>Registrieren</slot>
  </button>
</template>

<script>
export default {
  name: 'FmuButton',
  components: {},
  emits: ["button-click"],
  props: {
    buttonType: {
      type: String,
      default: 'filled'
    },
    style: String,
    class: String,
    type: String,
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
</style>
