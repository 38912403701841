import {defineStore} from 'pinia'
import axios from "axios";
import {useMediaFilterStore} from "@/store/media/media-filter-store";
import {useUserStore} from "@/store/user/user-store";
import {useNotifyStore} from "@/store/notify/notify-store";
import Cookies from "js-cookie";
import {baseUrl} from "@/configurations/configurations";
import {ElLoading} from "element-plus";
import emitter from "../../services/emitter";
import {sortingFilterUser} from "../../configurations/configurations";
// import _ from "lodash"

export const useMediaStore = defineStore({
    id: 'mediaStore',
    state: () => ({
        scroll: false,
        allThumbsLoaded: false,
        partialThumbs: [],
        allThumbs: [],
        partialThumbsLoaded: false,
        thumbs: [],
        filteredThumbs: [],
        media: [],
        media_counted: 0,
        loading: false,
        media_sum: 0,
        topicWorlds: {},
        topicWorld: '',
        topicWorldIds: [],
        mediaTypeId: null,
        campaignId: null,
        paginatorState: 0,
        mediaGrid: []
    }),
    getters: {},
    actions: {
        getAllTopicWorlds() {
            axios.get('topic-worlds/get-topics-worlds').then(res => {
                this.topicWorlds = res.data;
            })
        },
        async getMediaSum() {
            let loading = ElLoading.service({target: '#grid-body', background: 'rgba(255,255,255, 0.5)'})

            await axios.get('media/get-media-sum').then(res => {
                this.media_sum = res.data.media_sum
                loading.close()
            }).catch(() => {
                loading.close()
            })
        },
        getAllMediaThumbs() {
            if (this.thumbs && this.thumbs.length === 0) {
                let loading = ElLoading.service({target: '#grid-body', background: 'rgba(255,255,255, 1)'})
                axios.get(`media/get-thumbs`).then((res) => {
                    this.thumbs = res.data.thumbs;
                    loading.close()
                }).catch(() => {
                    loading.close()
                }).then(() => {
                    let mediaFilterStore = useMediaFilterStore();
                    this.filterThumbs(mediaFilterStore.searchString ? 'all' : null);
                }).then(() => {
                    this.setMediaStates();
                })

            }
        },
        async getMediaThumbs() {
            let loading = ElLoading.service({target: '#grid-body', background: 'rgba(255,255,255, 0.5)'})
            this.loading = true;
            await axios.get(`media/get-thumbs/${this.media_counted}/partial`).then((res) => {
                this.partialThumbs = res.data.thumbs;
                this.thumbs = res.data.thumbs;

                this.media_sum = res.data.media_sum
                this.media_counted = res.data.media_counted
                this.filterThumbs();

                loading.close()
                this.loading = false;
            }).catch(() => {
                loading.close()
                this.loading = false;
            })
        },
        async getMedia(slug) {
            let loading = ElLoading.service({fullscreen: true, background: 'rgba(255,255,255,1)'})
            await axios.post('media/get-media', {
                slug: slug
            }).then((res) => {
                this.media = res.data;
                loading.close()
            }).catch(() => {
                loading.close()
                // window.location.replace("/");
            })
        },

        /**
         * filters thumbs by searching, setting filters and
         * switching categories
         */
        filterThumbs() {
            const mediaFilterStore = useMediaFilterStore();

            if (this.thumbs.length > 0) {

                emitter.emit('close-loading-grid-body')

                this.filteredThumbs = this.thumbs.filter(thumb => {

                    const optionsArrayWithIntValues = thumb.filter_options?.map(a => parseInt(a)) ?? [];
                    const mediaTypesWithStringValue = thumb.media_types?.map(a => a.toString()) ?? [];

                    let thumbHasFilter = true;
                    let thumbIsInCategory = true;
                    let searchStringMatches = true;

                    // filters by primary sorting (downloads & fav)
                    let sortingFilter = this.downloadOrFavoriteSorting(mediaFilterStore, thumb);

                    // filters by given filter
                    if (mediaFilterStore.filterModel.flat().length > 0) {
                        thumbHasFilter = Object.values(mediaFilterStore.filterModel.flat()).every(i => optionsArrayWithIntValues.includes(i));
                    }

                    // filters by media type (categories)
                    if (mediaFilterStore.category) {
                        thumbIsInCategory = Object.values(mediaTypesWithStringValue).some(i => mediaFilterStore.category.media_types?.includes(i));
                    }

                    // filter by search query (searches in title, filters and tags)
                    if (mediaFilterStore.searchString) {
                        searchStringMatches = thumb.title.toLowerCase().includes(mediaFilterStore.searchString.toLowerCase())
                            || (thumb.filter_options_as_string && thumb.filter_options_as_string?.some(i => i.toLowerCase().includes(mediaFilterStore.searchString.toLowerCase())))
                            || (thumb.tags && thumb.tags?.some(i => i.toLowerCase().includes(mediaFilterStore.searchString.toLowerCase())));

                        // filter by topic world
                        if (mediaFilterStore.searchString.toLowerCase().includes('themenwelt:')) {

                            this.topicWorld = mediaFilterStore.searchString.toLowerCase().replace('themenwelt: ', '');
                            this.topicWorlds.forEach(item => {
                                if (item.title.toLowerCase() === this.topicWorld) {
                                    this.topicWorldIds = Object.values(item.bm_topic_world_media);
                                }
                            })

                            searchStringMatches = this.topicWorldIds.includes(thumb.id)
                        } else {
                            if (this.topicWorldIds.length > 0) {

                                // reset topic world ids
                                this.topicWorldIds = [];
                            }
                        }
                    }

                    return thumbIsInCategory && thumbHasFilter && searchStringMatches && sortingFilter;
                })

                // show always the new stuff if primary sorting filter is not highlights
                if (mediaFilterStore.searchString || mediaFilterStore.filterModel.flat().length > 0 || mediaFilterStore.category) {
                    this.filteredThumbs.sort(function (a, b) {
                        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                    });
                }

                // reorder by topic world
                if (this.topicWorldIds.length > 0) {
                    let that = this;
                    this.filteredThumbs.sort(function (a, b) {
                        return that.topicWorldIds.indexOf(a.id) - that.topicWorldIds.indexOf(b.id);
                    });
                }

                // filters by primary sorting (highlight & date)
                this.highlightOrDateSorting(mediaFilterStore);

                this.filteredThumbs = this.chunkMediaGrid();
                this.mediaGrid = this.filteredThumbs[0];
                this.paginatorState = 0;
            } else {
                let loading = ElLoading.service({target: '#grid-body', background: 'rgba(255,255,255, 0.5)'})

                emitter.on('close-loading-grid-body', () => {
                    loading.close()
                })
            }
        },

        chunkMediaGrid() {
            let chunked = [];
            let tempArray = [];
            let thumbCounter = 0;

            if (this.filteredThumbs.length < 20) {
                return [this.filteredThumbs];
            }

            this.filteredThumbs.forEach((thumb, index) => {
                if ((thumbCounter >= 20 || (index === this.filteredThumbs.length - 1 && thumbCounter < 20)) && thumbCounter !== 0) {
                    chunked.push(tempArray);
                    tempArray = [];
                    thumbCounter = 0;
                }

                // disable big preview image to keep 4x5 grid consistent
                if (thumbCounter >= 12 && thumbCounter !== 0 && thumb.big_preview_image) {
                    thumb.big_preview_image = false;
                }

                if (thumb.big_preview_image) {
                    thumbCounter += 4;
                } else {
                    thumbCounter += 1;
                }

                tempArray.push(thumb);
            })

            return chunked;
        },
        loadMoreThumbs() {
            if (this.filteredThumbs.length - 1 > this.paginatorState) {
                this.paginatorState++;
                this.mediaGrid.push(...this.filteredThumbs[this.paginatorState]);
            }
        },

        /**
         * sorts thumbs either by highlights (downloads) or by date
         * @param mediaFilterStore
         */
        highlightOrDateSorting(mediaFilterStore) {
            if (mediaFilterStore.primarySortingFilter === 'Neueste') {
                this.filteredThumbs.sort(function (a, b) {
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                });
            }

            if (mediaFilterStore.primarySortingFilter === 'Älteste') {
                this.filteredThumbs.sort(function (a, b) {
                    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
                });
            }
        },

        /**
         * sorts thumbs either by downloads or favs
         * @param mediaFilterStore
         * @param thumb
         * @returns {boolean}
         */
        downloadOrFavoriteSorting(mediaFilterStore, thumb) {
            let sortingFilter = true;

            if (mediaFilterStore.primarySortingFilter) {
                switch (mediaFilterStore.primarySortingFilter) {
                    case sortingFilterUser[2]:
                        sortingFilter = thumb.states.includes('downloaded')
                        break;
                    case sortingFilterUser[3]:
                        sortingFilter = thumb.states.includes('favourite')
                        break;
                }
            }

            return sortingFilter;
        },

        /**
         * downloads media by media (media mime type & media file name),
         * media type and media id
         */
        download() {
            const userStore = useUserStore();

            let media = this.media[`media_type_${this.mediaTypeId}`]
            let loading = ElLoading.service({target: '#download-table'})

            let mimeType = media.zip_file ? 'application/zip' : media.mime_type
            let fileName = media.zip_file ?? media.filename;

            axios.post('media/download-media', {
                media_type: this.mediaTypeId,
                media_id: this.media.id,
                user_id: userStore.user.id
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': mimeType,
                },
                responseType: 'blob'
            }).then((response) => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
                loading.close();

                this.addState('downloaded', this.media.id);
                this.resetThumbs();
            }).catch(() => {
                loading.close();
            });
        },
        addFavourite(index, id) {
            const notifyStore = useNotifyStore();

            axios.post('media/state/favourite-action', {
                media_id: id,
                action: 'add'
            }).then(() => {
                this.addState('favourite', id);
                this.resetThumbs();
                notifyStore.notify('Erfolgreich', 'Produkt wurde als Favorit hinzugefügt')
            })
        },

        removeFavourite(itemIndex, id) {
            const notifyStore = useNotifyStore();

            axios.post('media/state/favourite-action', {
                media_id: id,
                action: 'remove'
            }).then(() => {

                this.filteredThumbs.forEach(arrays => {
                    arrays.forEach(thumb => {
                        if (thumb.id == id) {
                            let arrayIndex = thumb.states.indexOf('favourite')
                            thumb.states.splice(arrayIndex, 1)
                        }
                    })
                })

                this.thumbs.forEach(thumb => {
                    if (thumb.id == id) {
                        let arrayIndex = thumb.states.indexOf('favourite')
                        thumb.states.splice(arrayIndex, 1)
                    }
                })

                this.resetThumbs();
                this.filterThumbs();
                notifyStore.notify('Erfolgreich', 'Produkt wurde als Favorit entfernt')
            })
        },

        /**
         * redirect to smartkis v3 campaigns (selected one will be created)
         */
        appProcess() {
            window.open(`${baseUrl}fmu/app/${this.campaignId}/${Cookies.get('token')}`, "_blank");
        },

        resetThumbs() {
            localStorage.removeItem('allThumbs')
            localStorage.setItem('allThumbs', JSON.stringify(this.thumbs))
        },

        addState(state, id) {
            this.filteredThumbs.forEach(arrays => {
                arrays.forEach(thumb => {
                    if (thumb.id == id) {
                        if (!thumb.states) {
                            thumb.states = [];
                        }
                        thumb.states.push(state)
                    }
                })
            })

            this.thumbs.forEach(thumb => {
                if (thumb.id == id) {
                    if (!thumb.states) {
                        thumb.states = [];
                    }
                    thumb.states.push(state)
                }
            })
        },
        setMediaTypeId(id, campaignId = null) {

            if (campaignId) {
                this.campaignId = campaignId;
            }

            this.mediaTypeId = id;
        },
        setMediaStates() {
            axios.get(`media/get-states`).then(res => {
                this.thumbs.forEach(thumb => {
                    res.data.forEach(state => {
                        if (thumb.id == state.fmu_media_id) {
                            thumb.states.push(state.state)
                        }
                    })
                })
            })
        }
    },
})
