<template>
  <div class="py-6 relative">
    <input type="radio" id="debit" class="mt-1" value="debit"
           v-model="payment.paymentProvider">
    <label for="debit" class="text-lg font-semibold pl-2">SEPA-Lastschrift</label>
    <img :src="require('@/assets/images/sepa_logo.png')" alt="Sepa Logo" class="h-12 pr-2 absolute right-0 top-3">
  </div>
  <div v-if="payment.paymentProvider === 'debit'">
    <div class="pb-2">
      <input type="text" placeholder="Nachname Karteninhaber" class="fmu-input"
             name="holder"
             @focusout="checkInput($event, payment.debit)" :class="{'border-red-500': inputError.holder}"
             v-model="payment.debit.holder">
    </div>
    <div class="pb-2 flex">
      <div class="w-2/3 mr-2">
        <input type="text" placeholder="IBAN" class="fmu-input" v-model="payment.debit.iban"
               name="iban"
               @focusout="checkIban"
               :class="{'border-red-500': inputError.iban || (payment.debit.iban && !inputError.ibanIsValid)}"
               v-mask="'AA## #### #### #### #### ##'">
        <small class="text-red-500" v-if="payment.debit.iban && !inputError.ibanIsValid">Ungültige IBAN</small>
      </div>
      <div class="w-1/3">
        <input type="text" placeholder="BIC" class="fmu-input" v-model="payment.debit.bic"
               name="bic"
               @focusout="checkInput($event, payment.debit)" :class="{'border-red-500': inputError.bic}"
               v-mask="'AAAAAAAAAAA'" disabled>
      </div>
    </div>
    <select class="fmu-select" v-model="form.country" v-if="newAboModalStore.addressExists || $props.isForCourse">
      <option value="DE" selected>Deutschland</option>
      <option value="AT">Österreich</option>
      <option value="CH">Schweiz</option>
    </select>
    <div class="text-12px font-thin break-normal mt-2 text-justify">
      <p class="font-semibold">Hiermit bestätige ich Folgendes:</p>
      Mit dem Drücken des Buttons „Bestellen“ ermächtige ich die Hutter & Unger GmbH, die anfallenden Kosten meines Abos
      je nach ausgewählter Taktung monatlich oder jährlich widerruflich von dem oben genannten Bankkonto abzubuchen. Ich
      verpflichte mich hiermit, Änderungen meiner Bankverbindung umgehend mitzuteilen. Gescheiterte Lastschriften werden
      mir mit 8 € pro Vorgang in Rechnung gestellt.
    </div>
  </div>
</template>

<script>
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import {mask} from "vue-the-mask";
import {useSubscribeCourseModalStore} from "@/store/modals/subscribe-course-modal-store";
import {ref} from "vue"
import {inputError, checkInput} from "../../../../../services/register/inputChecker";
import axios from "axios";

export default {
  name: 'Debit',
  props: ['isForCourse'],
  directives: {
    mask
  },
  components: {},
  setup(props) {
    let IBAN = require('iban')

    const subscribeCourseStore = useSubscribeCourseModalStore();
    const newAboModalStore = useNewAboModalStore()

    const form = props.isForCourse ? subscribeCourseStore.form : newAboModalStore.form
    const payment = props.isForCourse ? subscribeCourseStore.payment : newAboModalStore.payment

    const errors = ref({
      holder: false,
      iban: false,
      bic: false,
      ibanIsValid: false
    })

    inputError.value = Object.assign(errors)

    const checkIban = (event) => {
      checkInput(event, payment.debit)
      inputError.value['ibanIsValid'] = IBAN.isValid(payment.debit.iban);

      axios.get(`https://openiban.com/validate/${payment.debit.iban}?getBIC=true`).then(res => {
        if (res.data.bankData.bic) {
          payment.debit.bic = res.data.bankData.bic
        } else {
          payment.debit.bic = ''
        }
      })
    }

    return {newAboModalStore, subscribeCourseStore, form, payment, checkInput, inputError, checkIban}
  }
}
</script>

<style scoped>
</style>
