import {defineStore} from 'pinia'

export const useLoginModalStore = defineStore({
    id: 'loginModalStore',
    state: () => ({
        isOpen: false,
        passwordResetOpen: false,
        form: {
            email: '',
            pw: '',
            remember: false
        },
        errorMsg: ''
    }),
    getters: {},
    actions: {
        toggle() {
            this.isOpen = !this.isOpen;
        },
        togglePasswordReset() {
            this.passwordResetOpen = !this.passwordResetOpen;
        },
        setErrorMsg(msg) {
            this.errorMsg = msg;
        },
        setLoginForm(form) {
            this.form.email = form.email;
            this.form.pw = form.password
        }
    },
})
