<template>

  <!--  DESKTOP-->
  <Carousel :breakpoints="breakpoints" :wrap-around="false" class="hidden md:block">
    <Slide v-for="topicWorld in mediaStore.topicWorlds" :key="topicWorld.id">
      <div class="topic-world-wrapper" style="margin: 0 5px !important;">
        <div @click="selectTopicWorld(topicWorld)" class="cursor-pointer border">
          <img
              :src="topicWorld.preview_image"
              class=""
          />
          <div class="py-5 md:px-5 md:text-left font-sans">
            <strong class="text-xl text-black">{{ topicWorld.title }}</strong> <br>
            <div class="pt-2 topic-world-description" v-html="topicWorld.description_topic_world ?? topicWorld.description"></div>
          </div>
        </div>
      </div>
    </Slide>
    <template #addons="{ slidesCount }">
      <Navigation v-if="(widthIs('>', 425) && slidesCount > 3) || widthIs('<=', 425)"/>
    </template>
  </Carousel>

  <!--  MOBILE-->
  <div class="cursor-pointer border mx-5 relative md:hidden">
    <img
        :src="mediaStore.topicWorlds[slide].preview_image"
        @click="selectTopicWorld(mediaStore.topicWorlds[slide])"
    />
    <div class="py-5 md:px-5 md:text-left font-sans" @click="selectTopicWorld(mediaStore.topicWorlds[slide])">
      <strong class="text-xl text-black">{{ mediaStore.topicWorlds[slide].title }}</strong> <br>
      <div class="pt-2">{{ mediaStore.topicWorlds[slide].description }}</div>
    </div>
    <button type="button" class="carousel__next" aria-label="Navigate to next slide" @click="nextSlide" v-if="slide !== mediaStore.topicWorlds.length-1">
      <svg class="carousel__icon" viewBox="0 0 24 24" role="img" ariaLabel="arrowRight"><title>arrowRight</title>
        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
      </svg>
    </button>
    <button type="button" class="carousel__prev" aria-label="Navigate to previous slide" @click="prevSlide" v-if="slide > 0">
      <svg class="carousel__icon" viewBox="0 0 24 24" role="img" ariaLabel="arrowLeft"><title>arrowLeft</title>
        <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
      </svg>
    </button>
  </div>
  <!--  <Carousel snapAlign="start" itemsToShow="1" :wrap-around="true" class="md:hidden">-->
  <!--    <Slide v-for="topicWorld in mediaStore.topicWorlds" :key="topicWorld.id">-->
  <!--      <div @click="selectTopicWorld(topicWorld)" class="cursor-pointer border carousel__item" style="padding: -120px">-->
  <!--        <img-->
  <!--            :src="topicWorld.preview_image"-->
  <!--            class=""-->
  <!--        />-->
  <!--        <div class="py-5 md:px-5 md:text-left font-sans">-->
  <!--          <strong class="text-xl text-black">{{ topicWorld.title }}</strong> <br>-->
  <!--          <div class="pt-2">{{ topicWorld.description }}</div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </Slide>-->
  <!--    <template #addons="{ slidesCount }">-->
  <!--      <Navigation v-if="(widthIs('>', 425) && slidesCount > 3) || widthIs('<=', 425)"/>-->
  <!--    </template>-->
  <!--  </Carousel>-->

</template>

<script>
import {Carousel, Slide, Navigation} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import {useMediaStore} from "../../../store/media/media-store";
import {ElCard} from "element-plus";
import {useMediaFilterStore} from "../../../store/media/media-filter-store";
import emitter from "../../../services/emitter";
import widthIs from "../../../validators/validators";
import {ref} from "vue";

export default {
  name: 'TopicWorlds',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ElCard,
    Carousel,
    Slide,
    Navigation
  },
  data() {
    return {
      breakpoints: {
        // 700px and up
        0: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
        // 1024 and up
        1024: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        1440: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
    }
  },
  setup() {
    const mediaStore = useMediaStore();
    const mediaFilterStore = useMediaFilterStore();

    const slide = ref(0);
    let topicLength = mediaStore.topicWorlds.length;

    const nextSlide = () => {
      if (slide.value === topicLength - 1) {
        slide.value = 0;
      } else {
        slide.value++;
      }
    }

    const prevSlide = () => {
      if (slide.value === 0) {
        slide.value = topicLength - 1;
      } else {
        slide.value--;
      }
    }

    const selectTopicWorld = (topicWorld) => {
      let searchString = 'Themenwelt: ' + topicWorld.title;

      mediaFilterStore.searchString = searchString
      emitter.emit('search-content', {search: searchString})
    }

    return {mediaStore, selectTopicWorld, widthIs, nextSlide, prevSlide, slide}
  }
}
</script>

<style>
.carousel__prev, .carousel__next {
  background: #FD470F !important;
}

.topic-world-wrapper > div .el-card.is-always-shadow {
  box-shadow: unset !important;
}

@media only screen and (max-width: 425px) {
  .topic-parent > .carousel > .carousel__next {
    right: 25px !important;
  }

  .topic-parent > .carousel > .carousel__prev {
    left: 25px !important;
  }
}

.carousel__prev--in-active,
.carousel__next--in-active {
  display: none !important;
}
</style>
