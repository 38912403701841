import {defineStore} from 'pinia'
import axios from "axios";
import {ElLoading} from "element-plus";

export const useSubStore = defineStore({
    id: 'subStore',
    state: () => ({
        subs: [],
        subOptions: [],
        subAfterFreeTrial: {},
        errorMsg: '',
        maxComparisonItems: 0,
        periodSwitcher: false
    }),
    getters: {},
    actions: {
        setSubs(subs) {
          this.subs = subs
        },
        async getSubs() {
            axios.get('subscriptions/get-subscriptions').then((res) => {
                this.subs = res.data.subs;
                this.subAfterFreeTrial = res.data.sub_after_free_trial

                this.subs.forEach(sub => {
                    if (this.maxComparisonItems < sub.sub_content_texts?.length) {
                        this.maxComparisonItems = sub.sub_content_texts?.length;
                    }
                })
            })
        },
        async getSubOptions() {
            if (this.subOptions.length === 0) {
                axios.get('subscriptions/get-sub-options').then((res) => {
                    this.subOptions = res.data;
                })
            }
        },
        finalPaymentProcess(status, token) {
            let loading = ElLoading.service({fullscreen: true})
            axios.post('user/final-payment-process', {
                status: status,
                token: token,
            }).then(() => {
                loading.close()
            }).catch(() => {})
        },
        /**
         * calculates pseudo percentage discount
         * @param sub
         * @returns {number}
         */
        getPercentageDiscount(sub) {
            return 100 - Math.round(sub.price_yearly * 100 / (sub.price_monthly * 12));
        },
        getSubOptionsValues() {
            let options = [{title: 'Alle Abos'}];

            this.subs.forEach(i => {
                options.push(i)
            })

            return options;
        }
    },
})
