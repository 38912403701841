<template>
  <div class="pb-36 px-8" v-if="newAboModalStore.subsAlreadyBooked.length > 0">
    <p class="modal-headline text-center px-24 font-semibold break-normal">
      Folgende Abos hast Du bereits abgeschlossen:
    </p>
    <div class="pt-10">
      <table class="w-full table table">
        <tr class="bg-gray-200 font-semibold">
          <td class="p-2">Abo</td>
          <td class="text-right p-2">gebucht am</td>
        </tr>
        <tr class="font-thin border-b border-gray-200 px-2" v-for="subBooked in newAboModalStore.subsAlreadyBooked" :key="subBooked.name">
          <td class="p-2">{{ subBooked.name }}</td>
          <td class="text-right p-2">{{ moment(subBooked.created_at).format('DD.MM.YYYY') }}</td>
        </tr>
      </table>
      <br>
      <p class="font-thin">
        Eine Doppelbuchung ist nicht möglich. <br> <span class="font-semibold">Bitte die bereits gebuchte Abos aus dem Warenkorb entfernen.</span>
      </p>
    </div>
  </div>
  <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 flex justify-center">
    <fmu-button button-type="outline" type="button" @button-click="newAboModalStore.goToStep(basketStep)" class="mr-4">
      zurück
    </fmu-button>
  </div>
</template>

<script>
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import FmuButton from "@/components/theme/button/FmuButton";
import moment from "moment";
import {basketStep} from "../../../../configurations/payment/payment-config";

export default {
  name: 'SubAlreadyBookedContent',
  components: {FmuButton},
  emits: ['button-click'],
  setup() {
    const newAboModalStore = useNewAboModalStore();
    return {
      basketStep,
      newAboModalStore,
      moment
    }
  }
}
</script>

<style scoped>
.width-5rem {
  width: 5rem;
}
</style>
