<template>
  <div class="text-bm-font-gray-1">
    <navigation></navigation>
    <div class="">
      <router-view/>
    </div>
    <fmu-footer></fmu-footer>
  </div>
  <notify></notify>
  <new-sub-modal></new-sub-modal>
  <cookie-content-modal v-if="route.name !== 'privacy'"></cookie-content-modal>
</template>

<script>
import FmuFooter from './components/theme/footer/Footer.vue'
import Navigation from "./components/theme/header/Navigation.vue";
import Notify from "@/components/theme/notify/Notify";
import CookieContentModal from "@/components/modals/cookie-consent/CookieConsentModal";
import {useRoute} from "vue-router";
import {useBasketStore} from "@/store/basket/basket-store";
import NewSubModal from "@/components/modals/new_sub/NewSubModal";

export default {
  name: 'App',
  components: {
    NewSubModal,
    CookieContentModal,
    Notify,
    FmuFooter,
    Navigation,
  },
  setup() {
    const route = useRoute();
    const basketStore = useBasketStore()

    basketStore.initBasket();

    return {route}
  }
}
</script>
<style lang="scss">
@import "assets/style/index";
</style>
