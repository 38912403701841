<template>
  <img :src="require('@/assets/icons/home/cart.svg')" alt="Warenkorb" class="h-6 inline ml-3 cursor-pointer"
       @click="basket">
  <div class="bg-black rounded-full w-4 h-4 pt-0.5 text-center text-white absolute md:left-7 md:bottom-5 left-10" style="font-size: 8px" v-if="basketStore.basket.subs.length > 0">{{basketStore.basket.subs.length}}</div>
</template>

<script>
import {basketStep} from "@/configurations/payment/payment-config";
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import {useBasketStore} from "@/store/basket/basket-store";

export default {
  name: 'BasketIcon',
  components: {},
  setup() {
    const newAboStore = useNewAboModalStore();
    const basketStore = useBasketStore();

    const basket = () => {
      newAboStore.toggle()
      newAboStore.goToStep(basketStep)
    }
    return {basket, basketStore}
  }
}
</script>

<style scoped>
</style>
