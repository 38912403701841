<template>
  <nav class="bg-white py-2 md:px-12">
    <div class="max-w-full mx-auto pb-1 fmu-container">
      <div class="relative flex items-center justify-between h-20">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <button
              class="inline-flex items-center justify-center pl-4 focus:outline-none"
              aria-expanded="false" @click="toggleMobileNav">
            <span class="sr-only">Open main menu</span>
            <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
            </svg>
            <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <router-link to="/">
              <img class="block lg:hidden w-auto h-16" :src="require('@/assets/images/logo.svg')"
                   alt="logo" @click="resetFilters">
            </router-link>
            <router-link to="/">
              <img class="hidden lg:block w-auto h-16"
                   :src="require('@/assets/images/logo.svg')" @click="resetFilters" alt="logo">
            </router-link>
          </div>
          <div class="hidden sm:block sm:ml-12">
            <div class="flex space-x-4 font-sans font-light pt-2">
              <router-link :to="nav.url"
                           :class="[(nav.url === $route.path) || ($route.path === '/' && nav.name === 'Zu den Contents')  ? ['font-bold', 'text-bm-primary'] : '']"
                           v-for="nav in navigationStore.menu" :key="nav"
                           class="py-4 hover:text-bm-primary transition duration-500">{{ nav.name }}
              </router-link>
            </div>
            <dropdown-menu></dropdown-menu>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <div class="ml-3 relative text-sm">
            <nav-action-buttons class="hidden md:block"></nav-action-buttons>
          </div>
        </div>
      </div>
    </div>
    <div class="sm:hidden" :class="showMobileNav ? 'block' : 'hidden'">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <router-link class="block px-3 py-2 text-base" :to="nav.url"
                     :class="[(nav.url === $route.path) || ($route.path === '/' && nav.name === 'Zu den Contents')  ? ['text-bm-primary', 'font-bold'] : '']"
                     v-for="nav in navigationStore.menu" :key="nav">{{ nav.name }}
        </router-link>
        <nav-action-buttons class="sm:block md:hidden pt-2"></nav-action-buttons>
      </div>
      <dropdown-menu></dropdown-menu>
    </div>
  </nav>
</template>

<script>
import NavActionButtons from "./action-buttons/NavActionButtons";
import {useNavigationStore} from "@/store/navigation/navigation-store";
import {useUserStore} from "@/store/user/user-store";
import {ref} from "vue"
import DropdownMenu from "@/components/theme/header/dropdown_menu/DropdownMenu";
import {useMediaFilterStore} from "@/store/media/media-filter-store";

export default {
  name: 'Navigation',
  components: {
    DropdownMenu,
    NavActionButtons
  },
  props: {},
  setup() {
    const navigationStore = useNavigationStore();
    const mediaFilterStore = useMediaFilterStore();
    const userStore = useUserStore();
    const showMobileNav = ref(false);

    const resetFilters = () => {
      mediaFilterStore.selectCategory(null, true)
      mediaFilterStore.setPrimarySortingFilter(mediaFilterStore.sorting[0])
      mediaFilterStore.searchString = '';
      mediaFilterStore.filterModel = [];
    }

    return {
      userStore,
      navigationStore,
      showMobileNav,
      resetFilters
    }
  },
  methods: {
    toggleMobileNav() {
      this.showMobileNav = !this.showMobileNav;
    }
  }
}
</script>

<style scoped>

</style>
