import {defineStore} from 'pinia'

export const useNotifyStore = defineStore({
    id: 'notifyStore',
    state: () => ({
        visible: false,
        title: '',
        text: '',
        class: '',
        type: ''
    }),
    getters: {},
    actions: {
        notify(title, text, type = 'success') {
            this.type = type;
            this.visible = true;
            this.title = title;
            this.text = text;
            this.class = 'fade-in'
            setTimeout(() => {
                    this.class = 'fade-out'
                },
                5000)

            setTimeout(() => {
                    this.visible = false
                },
                6000)
        }
    },
})
