<template>
  <div class="pb-36 relative">
    <p class="modal-headline text-center px-24 font-semibold break-normal text-black">
      Warenkorb
    </p>
    <div class="md:px-48 pt-2" v-if="basketStore.basket.subs.length > 0">
      <div class="font-thin pb-4 text-xs text-center break-normal px-10">
        Dieses Angebot richtet sich ausschließlich an gewerbliche Kunden!
      </div>
      <div class="flex justify-center mb-2">
        <el-radio-group v-model="basketStore.basket.period" @change="getSum">
          <el-radio-button label="monthly">monatlich</el-radio-button>
          <el-radio-button label="yearly">jährlich</el-radio-button>
        </el-radio-group>
      </div>
      <br>

      <p class="text-right font-semibold pb-2">Netto-Preis</p>
      <div v-for="(item, index) in basketStore.basket.subs" :key="item.id"
           class="text-16px break-normal relative border-b border-gray pb-5 mb-4">
        <div class="flex">
          <div style="width: 300px" class="pr-8">
            <p class="text-xs font-thin">Basic-Marketing Abo</p>
            <p class="font-bold text-black">{{ item.title }}</p>
            <small class="font-thin float-left">{{ item.ordermail_text }}</small>
          </div>
          <div class="text-right" style="width: 150px">
            <p class="font-thin" v-if="basketStore.basket.period === 'yearly'">
              {{ formatCurrency(item.price_yearly) }} <small class="font-thin text-xss">/ Jahr</small></p>
            <p class="font-thin" v-if="basketStore.basket.period === 'monthly'">
              {{ formatCurrency(item.price_monthly) }} <small class="text-xxs font-thin">/ Monat</small></p>
          </div>
        </div>
        <div>
          <el-tooltip class="item" effect="dark" content="Aus dem Warenkorb entfernen" placement="right">
            <img :src="require('@/assets/icons/trash.svg')" alt="Löschen" class="h-4 inline mt-2 cursor-pointer"
                 @click="removeFromBasket(index)">
          </el-tooltip>
        </div>
      </div>
      <div class="pt-3 text-right font-thin">
        <p class="font-thin text-xxs text-red-500" v-if="basketStore.basket.coupon">
          {{ basketStore.basket.coupon.coupon_value }} {{ basketStore.basket.coupon.discount_type }} Rabatt für
          ein
          Jahr</p>
        <table class="float-right mb-8">
          <tr>
            <td class="pr-8 text-15px text-left">Gesampreis Netto</td>
            <td>
              <p class="text-15px font-thin" v-if="!basketStore.basket.coupon">{{ formatCurrency(total) }}</p>
              <div>
                <p class="font-thin text-red-500 text-15px"
                   v-if="basketStore.basket.period === 'monthly' && basketStore.basket.coupon">
                  {{ formatCurrency(basketStore.basket.coupon.total_monthly) }} / Monat <small
                    class="text-xxs font-thin"></small></p>
                <p class="font-thin text-red-500 text-15px"
                   v-if="basketStore.basket.period === 'yearly' && basketStore.basket.coupon">
                  {{ formatCurrency(basketStore.basket.coupon.total_yearly) }} / Jahr <small
                    class="text-xxs font-thin"></small>
                </p>
              </div>
            </td>
          </tr>
          <tr class="text-15px">
            <td class="text-left pr-4">zzgl. 19% gesetzl. Mwst.</td>
            <td v-if="basketStore.basket.period === 'monthly' && basketStore.basket.coupon">
              {{ formatCurrency(basketStore.basket.coupon.total_monthly * 0.19) }}
            </td>
            <td v-if="basketStore.basket.period === 'yearly' && basketStore.basket.coupon">
              {{ formatCurrency(basketStore.basket.coupon.total_yearly * 0.19) }}
            </td>
            <td v-if="!basketStore.basket.coupon">
              {{ formatCurrency(total * 0.19) }}
            </td>
          </tr>
          <tr class="text-15px font-semibold">
            <td class="text-left pr-4 text-black" style="border-top: 4px double #e5e7eb">Gesamtpreis Brutto</td>
            <td class="text-black" style="border-top: 4px double #e5e7eb"
                v-if="basketStore.basket.period === 'monthly' && basketStore.basket.coupon">
              {{ formatCurrency(basketStore.basket.coupon.total_monthly * 1.19) }}
            </td>
            <td class="text-black" style="border-top: 4px double #e5e7eb"
                v-if="basketStore.basket.period === 'yearly' && basketStore.basket.coupon">
              {{ formatCurrency(basketStore.basket.coupon.total_yearly * 1.19) }}
            </td>
            <td class="text-black" style="border-top: 4px double #e5e7eb" v-if="!basketStore.basket.coupon">
              {{ formatCurrency(total * 1.19) }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="px-64 py-5 text-center text-16px font-thin" v-else>
      Warenkorb ist leer.
    </div>
    <div class="fmu-error md:mx-48 mt-24" v-if="basketStore.errorMsg">
      {{ basketStore.errorMsg }}
    </div>
    <div class="text-center md:px-48 w-full pt-5 pb-4 font-semibold flex"
         v-if="!basketStore.basket.coupon && basketStore.basket.subs.length > 0">
      <el-input clearable type="text" name="coupon" v-model="basketStore.couponCode" placeholder="Ihren Code eingeben"
                class="bg-fmu-secondary w-full rounded focus:outline-none"
                @keyup.enter="basketStore.checkCoupon"></el-input>
      <button
          class="w-20 border border-fmu-fontcolor rounded ml-2 text-xs focus:outline-none hover:bg-black hover:text-white"
          @click="basketStore.checkCoupon">Einlösen
      </button>
    </div>
    <div class="flex justify-center mt-28" v-if="basketStore.basket.coupon && basketStore.basket.subs.length > 0">
      <div class="flex justify-center w-full md:w-1/2 bg-fmu-secondary py-4 ">
        <p class="text-xs font-thin">
          Gutschein <strong class="font-semibold">{{ basketStore.basket.coupon.coupon_code }}</strong> eingelöst
        </p>
        <img :src="require('@/assets/icons/cross.svg')" class="h-3 pt-1 pl-2 cursor-pointer" title="entfernen"
             @click="basketStore.removeCoupon">
      </div>
    </div>
    <div class="flex justify-center md:px-48 pt-3" v-if="basketStore.basket.subs.length > 0">
      <div class="break-normal hyphens text-justify text-12px">
        <p class="font-thin text-xs pb-4">
          Sie können Ihr Abo direkt nach Erhalt der Zugangsdaten nutzen. Ihr Abo beginnt zum 1. des Folgemonats und die
          Rechnungsstellung erfolgt zum 5. des darauffolgenden Monats.
        </p>
        <p class="font-medium">Content-Hub Nutzung included</p>
        <div class="font-thin">
          Veröffentliche direkt aus der Basic-Marketing Oberfläche Content auf Deinen Facebook, Instagram, Shopware,
          WooCommerce und App Kanälen - wir beraten Dich gerne zur Anbindung der Kanäle und erstellen ein Angebot.
          Schreibe uns über das
          <a href="/kontakt" target="_blank"
             class="underline font-medium">Kontaktformular</a>.
        </div>
      </div>
    </div>
    <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 flex justify-center">
      <div class="text-center block" v-if="basketStore.basket.subs.length > 0">
        <fmu-button button-type="filled" @button-click="newAboModalStore.goToStep(registerStep)" class="mb-4"
                    v-if="!userStore.user.isLoggedIn">
          Weiter zur Registrierung
          <img :src="require('@/assets/icons/chevron_right.svg')" alt="" class="h-5 pl-1 float-right">
        </fmu-button>
        <fmu-button button-type="filled" @button-click="newAboModalStore.goToStep(paymentProviderStep)" class="mb-4"
                    v-else>
          Weiter zu Zahlungsart wählen
          <img :src="require('@/assets/icons/chevron_right.svg')" alt="" class="h-5 pl-1 float-right">
        </fmu-button>
      </div>
      <div v-else>
        <fmu-button button-type="filled" class="mb-4" @button-click="goToSubs">
          Zu den Abos
          <img :src="require('@/assets/icons/chevron_right.svg')" alt="" class="h-5 pl-1 float-right">
        </fmu-button>
      </div>
    </div>
  </div>
</template>

<script>
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import FmuButton from "@/components/theme/button/FmuButton";
import {paymentProviderStep, registerStep} from "@/configurations/payment/payment-config";
import {useBasketStore} from "@/store/basket/basket-store";
import {formatCurrency} from "@/filter/currency";
import {ElInput, ElTooltip} from "element-plus"
import {ref} from "vue"
import {ElRadioGroup, ElRadioButton} from "element-plus"
import {useRouter} from "vue-router";
import {useUserStore} from "@/store/user/user-store";

export default {
  name: 'PaymentProviderContent',
  components: {FmuButton, ElTooltip, ElRadioGroup, ElRadioButton, ElInput},
  setup() {
    const newAboModalStore = useNewAboModalStore();
    const basketStore = useBasketStore();
    const userStore = useUserStore();

    const total = ref(0)
    const router = useRouter()

    const goToSubs = () => {
      newAboModalStore.toggle();
      router.push({path: '/abo-pricing', hash: '#subs'})
    }

    // const getTextArray = (sub) => {
    //   let array = [];
    //   if (sub.benefits && sub.benefits.length > 0) {
    //     sub.benefits.forEach(text => {
    //       array.push(text.benefit);
    //     })
    //   }
    //
    //   return array;
    // }

    const addToSum = (item) => {
      switch (basketStore.basketPeriod) {
        case 'yearly':
          total.value += parseInt(item.price_yearly)
          break;
        case 'monthly':
          total.value += parseInt(item.price_monthly)
          break;
      }
    }

    const getSum = () => {
      total.value = 0;
      basketStore.basket.subs.forEach(item => {
        if (item.coupon) {
          addToSum(item.coupon)
        } else {
          addToSum(item)
        }
      })
    }

    getSum();

    const removeFromBasket = (index) => {
      basketStore.removeFromBasket(index);
      getSum()
      basketStore.idsPush()
    }

    return {
      registerStep,
      paymentProviderStep,
      userStore,
      // getTextArray,
      goToSubs,
      getSum,
      removeFromBasket,
      formatCurrency,
      total,
      basketStore,
      newAboModalStore
    }
  },
}
</script>

<style scoped>
</style>
