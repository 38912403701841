import { defineStore } from 'pinia'

export const useNavigationStore = defineStore({
    id: 'navigationStore',
    state: () => ({
        menu: [
            {
                name: 'Zu den Contents',
                url: '/home'
            },
            {
                name: 'Dein Nutzen',
                url: '/dein-nutzen'
            },
            {
                name: 'Pricing',
                url: '/abo-pricing'
            },
            // {
            //     name: 'Onlineseminare',
            //     url: '/onlineseminare'
            // },
            {
                name: 'Kontakt',
                url: '/kontakt'
            }
        ],
        dropdownOpen: false
    }),
    getters: {},
    actions: {
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen
        },
        closeDropdown() {
            this.dropdownOpen = false;
        }
    },
})
