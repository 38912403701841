<template>
  <div class="cookie-consent-modal">
    <el-dialog
        title="Datenschutz"
        v-model="cookieConsentStore.isOpen"
        :close-on-click-modal="0"
        :close-on-press-escape="0"
        :show-close="0"
        :lock-scroll="0"
        width="400px"
        :fullscreen="widthIs('<', 400)">
      <div class="text-center">
        <div class="text-left break-normal">
          Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese
          Website
          und Ihre Erfahrung zu verbessern.
          <br><br>
          <div class="flex pb-5">
            <div>
              <input type="checkbox" name="" class="mr-1" id="essentially" checked disabled>
              <label for="essentially">Essenziell</label>
            </div>
            <div class="pl-5">
              <input type="checkbox" name="" class="mr-1" id="marketing" v-model="marketing">
              <label for="marketing">Marketing</label>
            </div>
          </div>
        </div>
        <div @click="accept()">
          <fmu-button class="w-full py-3">Alle akzeptieren</fmu-button>
        </div>
        <div @click="save()">
          <button class="px-5 py-3 rounded bg-gray-200 mt-2 w-full">Speichern</button>
        </div>
        <p>&nbsp;</p>
        <a href="/datenschutz" target="_blank" class="text-xs underline">Datenschutzerklärung</a>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {ElDialog} from "element-plus";
import {useCookieConsentStore} from "@/store/modals/cookie-consent-modal";
import {onMounted, ref} from "vue"
import FmuButton from "@/components/theme/button/FmuButton";
import widthIs from "@/validators/validators";
import Cookies from "js-cookie"
import { bootstrap } from 'vue-gtag'

export default {
  name: 'CookieConsentModal',
  components: {FmuButton, ElDialog},
  emits: ['button-click'],
  setup() {
    const cookieConsentStore = useCookieConsentStore()
    const marketing = ref(false)

    onMounted(() => {
        cookieConsentStore.checkForCookie()
    })

    const accept = () => {
      bootstrap().then(() => {
        Cookies.set('cookies-accepted', 1, {expires: 365})
        cookieConsentStore.toggle()
      })
    }

    const save = () => {
      if (marketing.value == true) {
        accept();
      } else {
        Cookies.set('cookies-accepted', 1, {expires: 365})
        cookieConsentStore.toggle()
      }
    }

    return {cookieConsentStore, widthIs, accept, marketing, save}
  }
}
</script>

<style>
.cookie-consent-modal > .el-overlay > .el-dialog > .el-dialog__body {
  padding: 0 20px 15px 20px !important;
}
</style>
