<template>
  <media-filter-primary></media-filter-primary>
  <media-filter-secondary></media-filter-secondary>
</template>

<script>
import MediaFilterPrimary from "./filters/MediaFilterPrimary";
import MediaFilterSecondary from "./filters/MediaFilterSecondary";

export default {
  name: 'Filters',
  components: {MediaFilterPrimary, MediaFilterSecondary}
}
</script>

<style scoped>
</style>
