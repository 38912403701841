<template>
  <!--  <div class="md:hidden px-4 py-4">-->
  <!--    <label for="" class="font-semibold">Abos</label>-->
  <!--    <el-select v-model="subOption" class="w-full" placeholder="Alle" @change="mediaFilterStore.changeSubOption">-->
  <!--      <el-option-->
  <!--          v-for="(item, id) in subStore.getSubOptionsValues"-->
  <!--          :key="item.id"-->
  <!--          :label="item.title"-->
  <!--          :value="id">-->
  <!--      </el-option>-->
  <!--    </el-select>-->
  <!--  </div>-->
  <div class="flex-grow font-thin text-16px px-4 pb-5" :class="$props.class">
    <Carousel :items-to-show="3.5" snapAlign="start"
              class="nav-mobile-slider">
      <Slide v-for="(sub, id) in subStore.getSubOptionsValues()" :key="sub.id">
            <span class="pt-2 px-2 cursor-pointer"
                  :class="[mediaFilterStore.category?.title === sub.title ? ['border-b-2 border-black'] : '']"
                  @click="mediaFilterStore.selectCategory(sub)" v-if="id !== 0">
              <p class="text-xs font-bold text-black">Abo {{ id }}</p>
              <p class="text-xs">{{ sub.title }}</p>
            </span>

        <span class="pt-2 cursor-pointer"
              :class="[mediaFilterStore.category == null ? ['text-black font-bold border-b-2 border-black'] : '']"
              @click="mediaFilterStore.selectCategory(sub)" v-if="id === 0">
              <p class="text-xs bg-bm-font-gray-3 py-2 px-4">{{ sub.title }}</p>
            </span>
      </Slide>
      <template #addons>
        <Navigation/>
      </template>
    </Carousel>
  </div>
</template>

<script>
import {ref} from "vue";
import {Carousel, Navigation, Slide} from 'vue3-carousel';

export default {
  name: 'Categories',
  props: ['class'],
  components: {Carousel, Slide, Navigation},
  setup() {
    const subOption = ref(0)
    return {subOption}
  }
}
</script>

<script setup>
import {ref} from "vue";
import {useSubStore} from "../../../../../store/subs/sub-store";
import {useMediaFilterStore} from "../../../../../store/media/media-filter-store";

const mediaFilterStore = useMediaFilterStore()
const subStore = useSubStore();
</script>

<style>
.nav-mobile-slider > .carousel__next, .nav-mobile-slider > .carousel__prev {
  background: none !important;
  color: #000 !important;
}
</style>
