import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/home/Home.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { title: 'Startseite' }
    },
    {
        path: '/home',
        redirect: '/'
    },
    {
        path: '/agb',
        name: 'agb',
        meta: { title: 'AGB' },
        component: () => import('../views/internal/Conditions')
    },
    {
        path: '/impressum',
        name: 'impress',
        meta: { title: 'Impressum' },
        component: () => import('../views/internal/Impress')
    },
    {
        path: '/datenschutz',
        name: 'privacy',
        meta: { title: 'Datenschutz' },
        component: () => import('../views/internal/Privacy')
    },
    {
        path: "/:catchAll(.*)",
        name: '404',
        meta: { title: 'Seite nicht gefunden' },
        component: () => import('../views/404/404')
    },
    // {
    //     path: '/onlineseminare',
    //     name: 'onlineseminare',
    //     meta: { title: 'Onlineseminare' },
    //     component: () => import('../views/online_courses/OnlineCourses.vue')
    // },
    {
        path: '/abo-pricing',
        name: 'pricing',
        meta: { title: 'Abo & Pricing' },
        component: () => import('../views/pricing/Pricing.vue')
    },
    {
        path: '/media/:slug',
        name: 'media',
        component: () => import('../views/media/Media.vue')
    },
    {
        path: '/success/:payment_token',
        name: 'payment_success',
        component: () => import('../views/payment/Success')
    },
    {
        path: '/error/:payment_token',
        name: 'payment_error',
        component: () => import('../views/payment/Error')
    },
    {
        path: '/canceled/:payment_token',
        name: 'payment_canceled',
        component: () => import('../views/payment/Canceled')
    },
    {
        path: '/einstellungen',
        name: 'settings',
        meta: { title: 'Einstellungen' },
        component: () => import('../views/settings/Settings.vue')
    },
    {
        path: '/kontakt',
        name: 'contact',
        meta: { title: 'Kontakt' },
        component: () => import('../views/contact/Contact')
    },
    {
        path: '/dein-nutzen',
        name: 'productInfo',
        meta: { title: 'Dein Nutzen' },
        component: () => import('../views/product-info/ProductInfo')
    },
    {
        path: '/login/:token',
        name: 'loginAsUser',
        component: () => import('../views/login-as-user/LoginAsUser')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {

        if (to.hash) {
            return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' });
        }

        if (savedPosition) {
            return savedPosition
        } else if (to.hash) {
            return { el: to.hash }
        } else {
            document.getElementById('app').scrollIntoView();
        }
    }
})

export default router
