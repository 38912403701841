<template>
  <div v-if="mediaFilterStore.secondaryFilterOpen" class="py-4">
    <div class="pt-2 grid grid-col-1 md:grid-cols-5 md:gap-3 font-thin text-sm"
         v-for="filterArray in mediaFilterStore.filter" :key="filterArray">
      <div v-for="filter in filterArray" :key="filter.id" class="px-4 md:px-0 py-2 md:py-0">
        <label for="" class="font-sans font-bold text-black">{{ filter.title }}</label>
        <el-select
            v-model="mediaFilterStore.filterModel[filter.id]"
            multiple
            collapse-tags
            class="w-full"
            placeholder="Auswählen"
        >
          <el-option
              v-for="item in filter.options"
              :key="item.id"
              :label="item.option"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import {ElSelect, ElOption} from 'element-plus'
import {useMediaFilterStore} from "@/store/media/media-filter-store";
import {useUserStore} from "@/store/user/user-store";

export default {
  name: 'MediaFilterSecondary',
  components: {
    ElSelect,
    ElOption,
  },
  setup() {
    const mediaFilterStore = useMediaFilterStore()
    const userStore = useUserStore();

    return {
      userStore,
      mediaFilterStore
    }
  },
}
</script>

<style scoped>
.search-input {
  font-size: 13px;
  padding: 0.4rem
}
</style>
