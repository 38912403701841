/**
 * checks if given form inputs are filled
 * @returns {boolean}
 */
export const isEmpty = (form, except = []) => {
    let isEmpty = false;
    Object.keys(form).forEach(x => {
        if (except && except.length > 0  && except.includes(x)) {
            return;
        }

        if (form[x] === '' || form[x] === null) {
            isEmpty = true;
        }
    })

    return isEmpty;
}

import {onMounted, onUnmounted, ref} from "vue"

/**
 * checks width with given operator
 * @param operator
 * @param width
 * @returns {any}
 */
export default function widthIs(operator, width) {
    let windowWidth = ref(window.innerWidth)

    const onWidthChange = () => windowWidth.value = window.innerWidth
    onMounted(() => window.addEventListener('resize', onWidthChange))
    onUnmounted(() => window.removeEventListener('resize', onWidthChange))

    return eval(windowWidth.value + operator + width);
}

/**
 * checks if given values are equal
 * @returns {boolean}
 */
export const valuesAreEqual = (value1, value2) => {
    return value1 === value2
}

/**
 * checks if given mail address is valid
 * @param email
 * @returns {boolean}
 */
export const mailIsValid = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const emptyErrorMsg = 'Bitte alle Felder ausfüllen.';
export const emailErrorMsg = 'Ungültige E-Mail-Adresse';
export const passwordsAreNotEqualErrorMsg = 'Die Passwörter stimmen nicht überein.';
export const passwordInvalid = 'Das Passwort erfüllt nicht die angegeben Anforderungen.';
