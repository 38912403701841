<template>
  <div class="pb-36">
    <p class="modal-headline text-center md:px-24 break-normal">
      Zahlungsart wählen und bestellen!
    </p>
    <div class="md:px-24 pt-5">

      <div v-if="newAboModalStore.errorMsg" class="fmu-error my-3">
        {{ newAboModalStore.errorMsg }}
      </div>
      <!--      <credit-card :is-for-course="$props.isForCourse"></credit-card>-->
      <!--      <hr>-->
      <!--      <debit :is-for-course="$props.isForCourse"></debit>-->
      <div :class="{'pt-6': payment.paymentProvider === 'debit'}">
        <!--        <hr>-->
        <unitex-invoice :is-for-course="$props.isForCourse"></unitex-invoice>
      </div>
      <hr>
      <invoice :is-for-course="$props.isForCourse"></invoice>
    </div>
    <div class="flex justify-center">
      <div class="pt-2 newsletter-checkbox">
        <el-checkbox v-model="sepaChecked" v-if="payment.paymentProvider === 'debit'" class="pt-5">
          Ich stimme den SEPA-Lastschrift Bedingungen zu.
        </el-checkbox>
        <br>
        <el-checkbox v-model="agbChecked" class="text-12px font-sans font-thin">
          Mit dem Drücken des Buttons „Bestellen“ akzeptiere ich die <br> Bestellbedingungen und die <a
            class="font-semibold" href="/agb" target="_blank">AGB</a> der Hutter & Unger GmbH.
        </el-checkbox>
      </div>
    </div>
    <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 flex justify-center">
      <fmu-button button-type="outline" @button-click="stepBack" class="mr-4">
        zurück
      </fmu-button>
      <fmu-button button-type="filled" @button-click="nextStep" v-if="!$props.isForCourse">
        Bestellen
        <img :src="require('@/assets/icons/chevron_right.svg')" alt="" class="h-5 pl-1 float-right">
      </fmu-button>
      <fmu-button button-type="filled" v-else @button-click="bookCourse">
        Onlinekurs buchen
        <img :src="require('@/assets/icons/chevron_right.svg')" alt="" class="h-5 pl-1 float-right">
      </fmu-button>
    </div>
  </div>
</template>

<script>
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import FmuButton from "@/components/theme/button/FmuButton";
import {ElCheckbox} from "element-plus"
import {ref} from "vue"
import {emptyErrorMsg} from "@/validators/validators";
import CreditCard from "@/components/modals/new_sub/content/payment/CreditCard";
import {useUserStore} from "@/store/user/user-store";
import {basketStep, registerStep} from "@/configurations/payment/payment-config";
import Debit from "@/components/modals/new_sub/content/payment/Debit";
import {useSubscribeCourseModalStore} from "@/store/modals/subscribe-course-modal-store";
import UnitexInvoice from "@/components/modals/new_sub/content/payment/UnitexInvoice";
import Invoice from "./payment/Invoice";

export default {
  name: 'PaymentProviderContent',
  // eslint-disable-next-line vue/no-unused-components
  components: {Invoice, UnitexInvoice, Debit, CreditCard, FmuButton, ElCheckbox},
  props: ['isForCourse'],
  setup(props) {
    var IBAN = require('iban');

    const newAboModalStore = useNewAboModalStore();
    const userStore = useUserStore();
    const subscribeCourseStore = useSubscribeCourseModalStore();
    const agbChecked = ref(false)
    const sepaChecked = ref(false)

    const payment = props.isForCourse ? subscribeCourseStore.payment : newAboModalStore.payment

    const inputsAreValid = () => {
      if (newAboModalStore.fieldsAreEmpty(props.isForCourse)) {
        newAboModalStore.setErrorMsg(emptyErrorMsg)
        return false;
      } else if (!agbChecked.value) {
        newAboModalStore.setErrorMsg('Um fortfahren zu können, musst Du unsere AGBs akzeptieren.')
        return false;
      } else if (payment.paymentProvider === 'debit') {
        if (!sepaChecked.value) {
          newAboModalStore.setErrorMsg('Um fortfahren zu können, musst Du unsere SEPA-Lastschrift Bedingungen akzeptieren.')
        } else if (!IBAN.isValid(payment.debit.iban)) {
          newAboModalStore.setErrorMsg('Die IBAN ist ungültig.');
        }
        return false;
      } else {
        return true;
      }
    }

    const nextStep = () => {
      if (inputsAreValid()) {
        newAboModalStore.subscribe();
      }
    }

    const bookCourse = () => {
      if (inputsAreValid()) {
        subscribeCourseStore.bookCourse()
      }
    }

    const stepBack = () => {
      if (userStore.user.isLoggedIn) {
        newAboModalStore.goToStep(basketStep)
      } else {
        newAboModalStore.goToStep(registerStep)
      }
    }

    return {
      payment,
      bookCourse,
      sepaChecked,
      stepBack,
      nextStep,
      agbChecked,
      newAboModalStore
    }
  },
}
</script>

<style scoped>
</style>
