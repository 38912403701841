<template>

  <!--  USER IS GUEST-->
  <div class="font-sans text-xs md:text-sm sm:hidden" :class="$props.class" v-if="!userStore.user.isLoggedIn">
    <basket-icon></basket-icon>
    <el-tooltip class="item" effect="dark" content="Suche" placement="bottom">
      <img :src="require('@/assets/icons/home/suche_nav.svg')" alt="Suche" class="h-6 inline ml-6 mr-3 cursor-pointer"
           @click="search">
    </el-tooltip>
    <fmu-button class="ml-3" @button-click="loginModalStore.toggle()">Anmelden</fmu-button>
  </div>

  <!--  USER IS LOGGED IN-->
  <div class="font-sans text-xs md:text-sm sm:hidden w-auto h-7" :class="$props.class" v-if="userStore.user.isLoggedIn">
    <basket-icon></basket-icon>
    <img :src="require('@/assets/icons/home/suche_nav.svg')" alt="Suche" class="h-6 inline ml-6 cursor-pointer"
         @click="search">
    <!--    <el-tooltip class="item" effect="dark" content="Downloads" placement="bottom">-->
    <!--      <img :src="require('@/assets/icons/download.svg')" alt="Downloads" class="h-6 inline ml-3 cursor-pointer"-->
    <!--           @click="downloads">-->
    <!--    </el-tooltip>-->
    <!--    <el-tooltip class="item" effect="dark" content="Favoriten" placement="bottom">-->
    <!--      <img :src="require('@/assets/icons/favoriten.svg')" alt="Favorites" class="h-6 inline ml-3 cursor-pointer"-->
    <!--           @click="favs">-->
    <!--    </el-tooltip>-->
    <img :src="require('@/assets/icons/home/settings.svg')" alt="Einstellungen"
         class="h-6 inline ml-6 cursor-pointer" @click="navigationStore.toggleDropdown()">
  </div>
  <login-modal></login-modal>
</template>

<script>
import FmuButton from "@/components/theme/button/FmuButton";
import {ElTooltip} from "element-plus"
import {useUserStore} from "@/store/user/user-store";
import {useLoginModalStore} from "@/store/modals/login-modal";
import {useNavigationStore} from "@/store/navigation/navigation-store";
import {useRouter} from "vue-router";
import LoginModal from "@/components/modals/login/LoginModal";
import {useBasketStore} from "@/store/basket/basket-store";
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import {basketStep} from "@/configurations/payment/payment-config";
import BasketIcon from "@/components/basket/BasketIcon";

export default {
  name: "NavActionButtons",
  props: ['class'],
  emit: ['button-click'],
  components: {
    BasketIcon,
    LoginModal,
    FmuButton,
    ElTooltip
  },
  setup() {
    const userStore = useUserStore();
    const loginModalStore = useLoginModalStore();
    const navigationStore = useNavigationStore();
    const basketStore = useBasketStore();
    const router = useRouter()
    const newAboStore = useNewAboModalStore();

    const search = () => {
      router.push({name: 'home', hash: '#filter'})
    }

    const basket = () => {
      newAboStore.toggle()
      newAboStore.goToStep(basketStep)
    }

    return {
      basket,
      navigationStore,
      loginModalStore,
      search,
      userStore,
      basketStore
    }
  }
}
</script>

<style scoped>

</style>
