<template>
  <div id="thumbs">
    <div class="pb-10" v-if="mediaStore.filteredThumbs.length > 0">
      <div class="grid md:grid-cols-3 lg:grid-cols-4 grid-cols-2 px-4 md:px-0 gap-5 pt-6">
        <div class="relative hover:shadow" :class="{'md:col-span-2 md:row-span-2': thumb.big_preview_image}"
             v-for="(thumb, index) in mediaStore.mediaGrid" :key="thumb.id">
          <div
              class="absolute uppercase text-xs bg-white text-black rounded font-0.6rem px-1 pt-0.5 left-2 top-2 font-thin download-badge z-50"
              v-if="thumb.states?.includes('downloaded')">heruntergeladen
          </div>
<!--          <div-->
<!--              class="absolute hover:bg-white hover:bg-opacity-70 w-full h-full text-center flex text-container cursor-pointer"-->
<!--              @click="clickOnMedia(thumb)">-->
<!--            <p class="text-xl font-bold px-12 m-auto opacity-0 item-text">{{ thumb.title }}</p>-->
<!--          </div>-->
          <div class="absolute bottom-2 right-2 cursor-pointer bg-white p-1 rounded" v-if="userStore.user.isLoggedIn">
            <el-tooltip effect="dark" content="Als Favorit speichern"
                        v-if="!thumb.states?.includes('favourite')">
              <img :src="require('@/assets/icons/home/favorite.svg')" alt="Favorite" class="h-4"
                   @click="mediaStore.addFavourite(index, thumb.id)">
            </el-tooltip>
            <img :src="require('@/assets/icons/home/favorite_full.svg')" alt="Favorite" class="h-4"
                 v-if="thumb.states?.includes('favourite')"
                 @click="mediaStore.removeFavourite(index, thumb.id)">
          </div>
          <img :src="getThumbUrl(thumb)"
               alt="Vorschaubild"
               @mouseover="mouseAction(thumb, true)"
               @mouseout="mouseAction(thumb, false)"
               @click="clickOnMedia(thumb)"
               class="w-full cursor-pointer"
               :class="`${thumb.big_preview_image ? 'md:h-690 md:w-690' : 'md:h-300 md:w-300'}`"
               loading="lazy">
        </div>
      </div>
    </div>
  </div>
  <div class="pt-6 flex h-56 pb-40" v-if="mediaStore.thumbs.length === 0">
    <h2 class="text-2xl m-auto">Medien werden geladen...</h2>
  </div>
  <div class="pt-6 flex h-56 pb-40" v-if="mediaStore.filteredThumbs.length === 0 && mediaStore.allThumbs.length !== 0">
    <h2 class="text-2xl m-auto">Keine Medien gefunden.</h2>
  </div>
  <choose-sub-notification-modal :media-type-ids="mediaTypeIds"></choose-sub-notification-modal>
</template>
<script>
import ChooseSubNotificationModal from "@/components/modals/choose_sub_notification/ChooseSubNotificationModal";
import {useChooseAboNotificationModalStore} from "@/store/modals/choose-abo-notification-modal-store";
import {useUserStore} from "@/store/user/user-store";
import {useMediaStore} from "@/store/media/media-store";
import {useMediaFilterStore} from "@/store/media/media-filter-store";
import {ElTooltip} from "element-plus"

import {useRouter} from 'vue-router'
import {ref, watch} from "vue"

export default {
  name: 'MediaGrid',
  emits: ['button-click'],
  components: {
    ChooseSubNotificationModal,
    ElTooltip,
  },
  setup() {
    const chooseAboNotificationModalStore = useChooseAboNotificationModalStore();
    const userStore = useUserStore();
    const mediaStore = useMediaStore();
    const mediaFilterStore = useMediaFilterStore();
    const router = useRouter()
    const productGrid = ref(null)
    const bottom = ref(null)
    const index = ref(0);

    const mediaTypeIds = ref([]);
    const isMouseOver = ref([]);

    /**
     * return mouseover image if array with true state is given
     * else return just the thumb image
     * @param thumb
     * @returns {*}
     */
    const getThumbUrl = (thumb) => {
      return isMouseOver.value[thumb.id] && thumb.mouseover_image ? thumb.mouseover_image : thumb.thumb
    }

    /**
     * add bool to isMouseOver array
     * to set state for mouseover change
     * @param thumb
     * @param bool
     */
    const mouseAction = (thumb, bool) => {
      isMouseOver.value[thumb.id] = bool;
    }

    const clickOnMedia = async (thumb) => {
      if (userStore.user.isLoggedIn) {
        await mediaStore.getMedia(thumb.slug);
        router.push(`/media/${thumb.slug}`)
      } else {
        mediaTypeIds.value = thumb.media_types;
        chooseAboNotificationModalStore.toggleModal();
      }
    }

    // const getImagesStyle = (thumb) => {
    //   if (thumb.big_preview_image) {
    //     return {height: 600, width: 600}
    //   }
    // }

    const countIndex = () => {
      index.value++;
    }

    /**
     * watches for changes in filters & category
     */
    watch(() => [
      mediaFilterStore.filterModel,
      mediaFilterStore.category,
      mediaFilterStore.searchString,
      mediaFilterStore.primarySortingFilter
    ], async () => {

      mediaStore.filterThumbs()
    }, {deep: true});

    return {
      index,
      countIndex,
      mediaFilterStore,
      bottom,
      mediaStore,
      productGrid,
      clickOnMedia,
      chooseAboNotificationModalStore,
      userStore,
      mediaTypeIds,
      getThumbUrl,
      mouseAction,
      isMouseOver
    }
  },
}
</script>

<style scoped>
.text-container:hover .item-text {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.relative:hover > .download-badge {
  color: white;
  background: #505050;
}

.font-0\.6rem {
  font-size: 0.6rem;
}
</style>
