<template>
  <div class="text-center font-serif pt-10"
       v-if="mediaStore.topicWorlds.length > 0">
    <div class="px-10 text-3xl md:text-5xl pt-5 md:pt-16 md:mx-12 pb-10 font-condensed font-bold text-black uppercase">
      DEINE AKTUELLEN TOPTHEMEN…
    </div>
    <div class="topic-parent" id="topic-parent">
      <topic-worlds></topic-worlds>
    </div>
    <!--    <br>-->
    <!--    <hr>-->
    <!--    <br>-->
    <!--    <br>-->
    <!--    <div class="text-lg md:text-3xl font-semibold pb-10">-->
    <!--      Ihre Vorteile auf einen Blick:-->
    <!--    </div>-->
    <!--    <br>-->
    <!--    <div class="grid grid-cols-1 md:grid-cols-3 text-lg md:text-xl">-->
    <!--      <benefit-box icon="home_benefits/icon-easy.svg" icon-class="h-8" title-sans="true">-->
    <!--        <template #title>-->
    <!--          Content easy & bequem downloaden-->
    <!--        </template>-->
    <!--      </benefit-box>-->
    <!--      <benefit-box icon="home_benefits/icon-cloud.svg" icon-class="h-8" title-sans="true">-->
    <!--        <template #title>-->
    <!--          In der Cloud jederzeit verfügbar-->
    <!--        </template>-->
    <!--      </benefit-box>-->
    <!--      <benefit-box icon="home_benefits/icon-download.svg" icon-class="h-8" title-sans="true">-->
    <!--        <template #title>-->
    <!--          Als Flatrate-Abo einmal gebucht, um nichts mehr kümmern-->
    <!--        </template>-->
    <!--      </benefit-box>-->
    <!--    </div>-->
    <!--    <div class="grid grid-cols-1 md:grid-cols-3 text-lg md:text-xl pt-10">-->
    <!--      <benefit-box icon="home_benefits/icon-expert.svg" icon-class="h-8" title-sans="true">-->
    <!--        <template #title>-->
    <!--          Topaktuell von Lifestyleexperten-->
    <!--        </template>-->
    <!--      </benefit-box>-->
    <!--      <benefit-box icon="home_benefits/icon-multichannel.svg" icon-class="h-8" title-sans="true">-->
    <!--        <template #title>-->
    <!--          All you can use – verwendbar für all Ihre Kanäle-->
    <!--        </template>-->
    <!--      </benefit-box>-->
    <!--      <benefit-box icon="home_benefits/icon-social-media.svg" icon-class="h-8" title-sans="true">-->
    <!--        <template #title>-->
    <!--          Facebook, Instagram, Youtube, Webshop, Newsblog, Website, Kunden-App, Instore-TV, Video-Streaming, Team-Schulungen uvm.-->
    <!--        </template>-->
    <!--      </benefit-box>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {useUserStore} from "@/store/user/user-store";
import {useRouter} from "vue-router";
import {useMediaStore} from "../../../store/media/media-store";
import {onMounted} from "vue";
import TopicWorlds from "../../home/topic-worlds/TopicWorlds";

export default {
  name: 'Jumbotron',
  components: {TopicWorlds},
  setup() {
    const userStore = useUserStore()
    const router = useRouter()

    const toSubs = () => {
      router.push({path: 'abo-pricing', hash: '#subs'})
    }

    const mediaStore = useMediaStore();

    onMounted(() => {
      mediaStore.getAllTopicWorlds()
    })

    return {
      toSubs,
      userStore,
      mediaStore
    }
  }
}
</script>
<style scoped>

</style>
