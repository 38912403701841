import {defineStore} from 'pinia'
import axios from "axios";
import {useLoginModalStore} from "@/store/modals/login-modal";
import {mailIsValid, emailErrorMsg, isEmpty} from "@/validators/validators";
import Cookies from "js-cookie"
import {ElLoading} from "element-plus";
import {useNotifyStore} from "@/store/notify/notify-store";

export const useUserStore = defineStore({
    id: 'userStore',
    state: () => ({
        user: {
            isLoggedIn: false
        },
        allowedOptions: [],
    }),
    getters: {},
    actions: {
        async login() {
            const loginModalStore = useLoginModalStore();

            if (isEmpty(loginModalStore.form)) {
                loginModalStore.setErrorMsg('Bitte fülle alle Felder aus.')
                return;
            }

            if (!mailIsValid(loginModalStore.form.email)) {
                loginModalStore.setErrorMsg(emailErrorMsg)
                return;
            }

            let loading = ElLoading.service({fullscreen: true})
            axios.post('user/login', {
                email: loginModalStore.form.email,
                password: loginModalStore.form.pw,
                application: 'FMU'
            }).then(async res => {
                loginModalStore.setErrorMsg('')

                let expires = {expires: 1};
                if (loginModalStore.form.remember) {
                    expires = {expires: 365}
                }

                Cookies.set('token', res.data.token, expires)
                await this.reAuth();

                window.location.replace("/");
            }).catch(error => {
                loading.close()
                if (error.response.status === 422) {
                    loginModalStore.setErrorMsg(error.response.data.message)
                }
            })
        },
        async reAuth() {
            await axios.get('user/get-user', {
                headers: {Authorization: `Bearer ${Cookies.get('token')}`}
            }).then(res => {
                this.user = res.data
                this.user.isLoggedIn = true;
            }).catch(() => {
                this.deleteToken()
            })
        },
        /**
         * if token is available
         * => delete
         */
        deleteToken() {
            if (Cookies.get('token')) {
                Cookies.remove('token');
            }
        },
        logout() {
            const notifyStore = useNotifyStore();

            let loading = ElLoading.service({fullscreen: true})
            axios.get('user/logout').then(() => {
                this.deleteToken();
                window.location.replace("/");
            }).catch(() => {
                loading.close()
                notifyStore.notify('Fehler', 'Bitte versuche es noch einmal', 'error')
            })
        }
    },
})

