<template>
  <div class="py-5 h-15">
    <input type="radio" id="credit_card" class="mt-3" value="credit_card"
           v-model="payment.paymentProvider">
    <label for="credit_card" class="text-lg font-semibold pl-2">Kreditkarte</label>
    <img :src="require('@/assets/images/cc_logos.png')" alt="Mastercard Logo" class="float-right h-10">
    <div v-if="payment.paymentProvider === 'credit_card'">
      <el-radio-group class="mt-2" v-model="payment.creditCard.cardtype"
                      @change="resetInputs">
        <el-radio-button label="V">VISA</el-radio-button>
        <el-radio-button label="M">MASTERCARD</el-radio-button>
      </el-radio-group>
      <div class="flex w-full mt-2">
        <div class="w-3/4 mr-2">
          <input type="text" class="fmu-input" v-model="payment.creditCard.cardpan"
                 placeholder="Kartennummer" v-mask="'################'" name="cardpan"
                 @focusout="checkInput($event, payment.creditCard)"
                 :class="{'border-red-500': inputError.cardpan}">
        </div>
        <div class="w-1/4">
          <input type="password" class="fmu-input" placeholder="CVC" v-mask="'####'"
                 @focusout="checkInput($event, payment.creditCard)"
                 :class="{'border-red-500': inputError.cardcvc2}"
                 v-model="payment.creditCard.cardcvc2" name="cardcvc2">
        </div>
      </div>
      <div class="flex">
        <div class="w-1/2 mr-2">
          <input type="text" class="fmu-input mt-2" placeholder="Vorname"
                 @focusout="checkInput($event, payment.creditCard)"
                 :class="{'border-red-500': inputError.first_name}"
                 v-model="payment.creditCard.first_name" name="first_name">
        </div>
        <div class="w-1/2">
          <input type="text" class="fmu-input mt-2" placeholder="Nachname"
                 @focusout="checkInput($event, payment.creditCard)"
                 :class="{'border-red-500': inputError.last_name}"
                 v-model="payment.creditCard.last_name" name="last_name">
        </div>
      </div>
      <div class="flex w-full mt-2">
        <div class="w-1/5 mr-4 text-center mt-2 font-semibold">
          <label>Gültig bis:</label>
        </div>
        <div class="w-2/5 mr-2">
          <select class="fmu-input h-9" v-model="payment.creditCard.expireMonth" name="expireMonth"
                  @focusout="checkInput($event, payment.creditCard)"
                  :class="{'border-red-500': inputError.expireMonth}">
            <option value="" selected disabled>Monat</option>
            <option :value="i" v-for="i in 12" :key="i">{{ zeroPad(i, 2) }}</option>
          </select>
        </div>
        <div class="w-2/5">
          <select class="fmu-input h-9" v-model="payment.creditCard.expireYear" name="expireYear"
                  @focusout="checkInput($event, payment.creditCard)"
                  :class="{'border-red-500': inputError.expireYear}">
            <option value="" selected disabled>Jahr</option>
            <option :value="year.toString().substr(-2)" v-for="year in getYears()" :key="year">{{ year }}</option>
          </select>
        </div>
      </div>
      <select class="fmu-select mt-2" v-model="form.country"
              v-if="newAboModalStore.addressExists || $props.isForCourse">
        <option value="DE" selected>Deutschland</option>
        <option value="AT">Österreich</option>
        <option value="CH">Schweiz</option>
      </select>
    </div>
  </div>
</template>

<script>
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import {ElRadioButton, ElRadioGroup} from "element-plus";
import {mask} from 'vue-the-mask'
import {zeroPad} from "@/filter/zeroPad";
import {useSubscribeCourseModalStore} from "@/store/modals/subscribe-course-modal-store";
import {checkInput, inputError} from "../../../../../services/register/inputChecker";

export default {
  name: 'CreditCard',
  components: {ElRadioGroup, ElRadioButton},
  props: ['isForCourse'],
  directives: {
    mask
  },
  setup(props) {
    const newAboModalStore = useNewAboModalStore()
    const subscribeCourseStore = useSubscribeCourseModalStore()

    const form = props.isForCourse ? subscribeCourseStore.form : newAboModalStore.form
    const payment = props.isForCourse ? subscribeCourseStore.payment : newAboModalStore.payment

    const resetInputs = () => {
      newAboModalStore.resetCreditCard()
      subscribeCourseStore.resetCreditCard();
    }

    const getYears = () => {
      const years = [];
      const year = new Date().getFullYear();
      years.push(year);

      for (let i = 1; i < 6; i++) {
        years.push(year + i);
      }

      return years;
    }

    const errors = {
      cardpan: false,
      cardcvc2: false,
      first_name: false,
      last_name: false,
      expireMonth: false,
      expireYear: false,
    }

    inputError.value = Object.assign(inputError.value, errors)

    return {
      inputError,
      newAboModalStore,
      getYears,
      mask,
      zeroPad,
      subscribeCourseStore,
      form,
      payment,
      resetInputs,
      checkInput
    }
  }
}
</script>

<style scoped>
</style>
