import {defineStore} from 'pinia'
import axios from "axios";
import {useUserStore} from "@/store/user/user-store";
import {sortingFilterGuest, sortingFilterUser} from "@/configurations/configurations";
import {useSubStore} from "../subs/sub-store";

export const useMediaFilterStore = defineStore({
    id: 'mediaFilterStore',
    state: () => ({
        categories: [],
        category: null,
        searchString: '',
        sorting: [],
        filter: [],
        primarySortingFilter: 'Alle',
        secondaryFilterOpen: false,
        focusOnSearch: false,
        suggestions: [],
        filterModel: [],
        componentKey: 1
    }),
    getters: {},
    actions: {
        setSearchString(string) {
            this.searchString = string;
        },
        async getAllFilter() {
            if (this.filter.length === 0) {
                await axios.get('filter/get-filter').then((res) => {
                    this.filter = res.data;
                })
            }
        },
        async getAllSuggestions() {
            if (this.suggestions.length === 0) {
                await axios.get('settings/get-suggestions').then((res) => {
                    if (res.data) {
                        this.suggestions = Object.values(res.data);
                    }
                })
            }
        },
        /**
         * sets primary filters for user & guest
         */
        setPrimaryFilters() {
            const userStore = useUserStore();
            if (userStore.user.isLoggedIn) {
                this.sorting = sortingFilterUser;
            } else {
                this.sorting = sortingFilterGuest;
            }
        },

        setPrimarySortingFilter(val) {
            this.primarySortingFilter = val;
        },

        /**
         * select category, if selectFirstItem = true then
         * first category will be selected
         * @param sub
         * @param selectFirstItem
         */ async selectCategory(sub, selectFirstItem = false) {
            if (selectFirstItem || sub.title === 'Alle') {
                this.category = null
            } else {
                this.category = {title: sub.title, id: sub.id, media_types: sub.media_types};
            }
        },
        changeSubOption(e) {
            const subStore = useSubStore();
            if (e === 0) {
                // set "Alle" as sub category
                this.selectCategory(null, true)
            } else {
                this.selectCategory(subStore.subs[e - 1])
            }
        },
        toggleSecondaryFilter() {
             this.secondaryFilterOpen = !this.secondaryFilterOpen;
        },
        forceRerenderComponent() {
             this.componentKey++;
        }
    },
})
