<template>
  <div class="chooseModal">
    <el-dialog
        title=""
        v-model="chooseAboNotificationModalStore.isOpen"
        width="700px"
        top="25vh"
        :fullscreen="widthIs('<', 700)"
        custom-class="relative">
      <p class="font-condensed text-black uppercase text-25px text-center px-24 font-semibold break-normal">
        Du interessierst Dich für den Content?
      </p>
      <br>
      <p class="text-sm text-center px-24 hyphens break-normal font-sans" v-if="!userStore.user.isLoggedIn">
        Dann schließe bitte zuerst Dein Abo ab, um uneingeschränkten Zugriff auf tausenden Fashion-Content zu
        erhalten!
      </p>
      <p class="text-sm text-center px-24 hyphens break-normal font-sans" v-else>
        Diese Kategorie ist in Deinem Abo nicht enthalten. Bitte schließe ein passendes Abo ab, um auf den Content
        zugreifen zu können.
      </p>
      <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 md:flex md:justify-center px-2 md:px-0">
        <a href="https://www.youtube.com/playlist?list=PLYhMdIyrtDnfCVS1IWHPEm0ez3-oVVVs2" target="_blank" class="mr-2"
           v-if="checkIfVideoContent()">
          <fmu-button button-type="primary" class="w-full md:w-44 mb-2 md:mb-0">Beispiel-Trend-Clip</fmu-button>
        </a>
        <a href="/abo-pricing">
          <fmu-button button-type="filled" class="w-full w-1">Weiter zu Abo & Pricing</fmu-button>
        </a>
        <fmu-button button-type="outline" @button-click="loginModalStore.toggle()" class="md:ml-2 w-full md:w-32 mt-2 md:mt-0">Login</fmu-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {ElDialog} from 'element-plus'
import {useChooseAboNotificationModalStore} from "@/store/modals/choose-abo-notification-modal-store";
import FmuButton from "@/components/theme/button/FmuButton";
import {useUserStore} from "@/store/user/user-store";
import widthIs from "../../../validators/validators";
import {useLoginModalStore} from "../../../store/modals/login-modal";

export default {
  name: 'ChooseSubNotificationModal',
  components: {
    ElDialog,
    FmuButton
  },
  props: ['mediaTypeIds'],
  emits: ['button-click'],
  setup(props) {
    const chooseAboNotificationModalStore = useChooseAboNotificationModalStore();
    const userStore = useUserStore()
    const loginModalStore = useLoginModalStore();

    const checkIfVideoContent = () => {
      let isVideo = false;
      props.mediaTypeIds.filter(id => {
        if (!isVideo) {
          isVideo = [13, 14, 15, 18, 19, 22, 23, 25, 27, 29].includes(id);
        }
      })

      return isVideo;
    }

    return {
      checkIfVideoContent,
      chooseAboNotificationModalStore,
      userStore,
      loginModalStore,
      widthIs
    }
  }
}
</script>

<style>
@media only screen and (min-width: 700px) {
  .chooseModal > .el-overlay > .el-dialog {
    height: 300px;
  }
}
</style>
<style scoped>
</style>
