<template>
  <div class="w-full relative py-20 md:py-36"
       :style="{ backgroundImage: 'url(\'' + require('@/assets/images/home/header.png') + '\')' }"
       style="background-repeat: no-repeat; background-size: cover">
    <div class="text-4xl md:text-headline font-condensed text-white font-bold text-center uppercase">
      <div style="line-height: 1.2" class="mx-10">
        Welche Marketingaktion suchst du?
      </div>
      <div class="flex justify-center">
        <div class="w-full searchinput relative hidden md:block mt-5" style="max-width: 700px">
          <img @click="search" :src="require('@/assets/icons/home/suche.svg')" alt="" style="height: 30px; z-index: 1"
               class="absolute right-5 top-6 cursor-pointer">
          <el-input
              v-model="searchString"
              @change="search"
              placeholder="Saisonstart, Weihnachten, Ostern..."
              class="input-with-select"
              size="large"
          >
            <template #prepend>
              <el-select v-model="subOption" style="min-width: 140px"
                         @change="changeSubOption">
                <el-option
                    v-for="(item, id) in subStore.getSubOptionsValues()"
                    :key="item.id"
                    :label="item.title"
                    :value="id">
                  <div :class="{'mt-3': id === 0}">
                    <p class="font-bold" v-if="id !== 0">Abo {{ id }}</p>
                    <span>{{ item.title }}</span>
                  </div>
                </el-option>
              </el-select>
            </template>
          </el-input>
        </div>
        <div class="w-full searchinput-mobile relative md:hidden mx-5 pt-5">
          <img @click="search" :src="require('@/assets/icons/home/suche.svg')" alt="" style="height: 30px; z-index: 1"
               class="absolute right-5 top-10 cursor-pointer">
          <el-input
              v-model="searchString"
              @change="search"
              placeholder="Saisonstart, Weihnachten, Ostern..."
          >
          </el-input>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-center">
    <div class="fmu-container">
      <jumbotron></jumbotron>
      <div
          class="text-3xl md:text-5xl font-condensed text-black font-bold uppercase text-center px-5 md:px-20 pt-14 md:pt-28"
          style="line-height: 1.25">
        Suche dir jetzt Deine Marketingaktion aus tausenden fertigen Vorlagen!
      </div>
      <filters></filters>
      <div id="grid-body">
        <media-grid></media-grid>
      </div>
        <div class="flex justify-center pb-40" v-if="
                        mediaStore.filteredThumbs.length > 0
                        && mediaStore.thumbs.length > 0
                        && mediaStore.filteredThumbs.length > 1
                        && mediaStore.filteredThumbs.length-1 > mediaStore.paginatorState">

        <fmu-button class="py-3 flex border-none" button-type="outline-light"
                    @button-click="mediaStore.loadMoreThumbs()">
          Mehr laden
          <img :src="require('@/assets/icons/home/chevron_down.svg')" alt="" style="height: 22px;"
               v-if="!mediaStore.loading">
          <half-circle-spinner
              :animation-duration="1000"
              :size="20"
              color="#B4b4b4"
              class="ml-2"
              v-if="mediaStore.loading"
          />
        </fmu-button>
      </div>
    </div>
  </div>
  <newsletter></newsletter>
</template>

<script>
import jumbotron from "@/components/theme/jumbotron/Jumbotron.vue"
import mediaGrid from "./media/MediaGrid.vue"
import Filters from "./media/Filters";
import Newsletter from "@/components/newsletter/Newsletter";
import FmuButton from "../../components/theme/button/FmuButton";
import {HalfCircleSpinner} from "epic-spinners";

import {ElInput, ElOption, ElSelect} from "element-plus";

export default {
  name: 'Home',
  components: {
    ElInput,
    ElSelect,
    ElOption,
    Newsletter,
    Filters,
    jumbotron,
    mediaGrid,
    FmuButton,
    HalfCircleSpinner,
  },
  setup() {
  }
}
</script>

<script setup>
import {onMounted, ref} from "vue";
import {useSubStore} from "../../store/subs/sub-store";
import {useMediaStore} from "../../store/media/media-store";
import {useMediaFilterStore} from "../../store/media/media-filter-store";
import emitter from "../../services/emitter";
import ErrorModal from "../payment/Error";

const searchString = ref('');
const subOption = ref(0)

const subStore = useSubStore();
const mediaStore = useMediaStore();
const mediaFilterStore = useMediaFilterStore()

onMounted(async () => {

  if (mediaStore.thumbs.length === 0) {
    // await mediaStore.getMediaThumbs();
    await mediaStore.getMediaSum()
    await mediaStore.getAllMediaThumbs()
    // await mediaStore.setThumbs('partial')
  }
  await mediaStore.filterThumbs()
})

const search = () => {
  emitter.emit('search-content', {search: searchString.value, sub: subOption.value})
}

const changeSubOption = (e) => {
  mediaFilterStore.changeSubOption(e);
  emitter.emit('change-sub-option', {sub: subOption.value})
}
</script>

<style>
.searchinput > .el-input > .el-input__inner {
  height: 60px !important;
  font-family: "Open Sans" !important;
  font-size: 16pt !important;
}

.searchinput > .el-input > .el-input-group__prepend > .el-select > .select-trigger > .el-input > .el-input__inner {
  height: 60px !important;
  color: #000 !important;
  font-family: "Open Sans" !important;
  background: #E0E3E3 !important;
}

.searchinput > .el-input > .el-input-group__prepend > .el-select > .select-trigger > .el-input > .el-input__inner::placeholder {
  color: #000;
  font-family: "Open Sans" !important;
  font-size: 16pt !important;
  margin-top: 50px !important;
  padding-top: 50px !important;
}

/*MOBILE*/
.searchinput-mobile > .el-input > .el-input__inner {
  height: 60px !important;
  font-family: "Open Sans" !important;
  font-size: 12pt !important;
}
</style>
