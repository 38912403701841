<template>
  <p class="font-serif text-25px text-center px-24 font-semibold break-normal">
    Jetzt gratis Abonnement starten
  </p>
  <br>
  <div v-if="newAboModalStore.errorMsg" class="fmu-error my-3">
    {{ newAboModalStore.errorMsg }}
  </div>
  <div class="border border-fmu-fontcolor rounded mx-48 pt-1 text-center">
    <div class="flex justify-center">
      <input type="radio" class="mt-3 mr-2 text-fmu-fontcolor" checked>
      <p class="font-serif text-2xl font-semibold">{{ newAboModalStore.sub.title }}</p>
    </div>
    <div class="px-6">
      <p class="text-sm text-center hyphens font-light pt-2 pb-4">
        {{ newAboModalStore.sub.description }}
      </p>
      <hr class="border-1 border-fmu-fontcolor">
      <p class="py-1 px-6">
        <b class="font-serif text-3xl">0 €</b>
        <small class="font-sans font-thin"> / erste 3 Monate</small>
      </p>
      <hr class="border-1 border-fmu-fontcolor">
      <p class="text-12px text-center hyphens font-light py-4">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aspernatur corporis deleniti ea esse est ex
        exercitationem illum.
      </p>
    </div>
  </div>
  <p class="abo-checkbox text-center px-24 pt-6 font-semibold break-normal">
    <el-checkbox v-model="newAboModalStore.freeAboAccepted">
      Ich bin damit einverstanden, dass nach Ablauf der 3 gratis Monate mein Abo sich automatisch verlängert und in ein
      Monats-Abo à {{subStore.subAfterFreeTrial.price_monthly}} € pro Monat übergeht. Die Rechnung wird mir zugesendet. Zahlungstermin gemäß Rechnung.
    </el-checkbox>
  </p>
</template>

<script>
import {ElCheckbox} from "element-plus"
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import {useSubStore} from "@/store/subs/sub-store";

export default {
  name: 'FreeSub',
  components: {ElCheckbox},
  setup() {
    const newAboModalStore = useNewAboModalStore();
    const subStore = useSubStore()

    return {
      subStore,
      newAboModalStore
    }
  }
}
</script>

<style scoped>
</style>
