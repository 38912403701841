<template>
  <div id="reset-modal">
    <p class="text-4xl text-center md:px-24 font-semibold break-normal">
      Passwort zurücksetzen
    </p>
    <div v-if="errorMsg" class="fmu-error mt-3 mx-12">
      {{ errorMsg }}
    </div>
    <div v-if="successMsg" class="mt-3 mx-12 fmu-success">
      {{ successMsg }}
    </div>
    <div class="mt-4 mb-24 px-12">
      <form @submit.prevent="resetPassword">
        <input type="text" class="fmu-input mb-10" name="email" placeholder="E-Mail-Adresse"
               v-model="loginModalStore.form.email">
      </form>
    </div>
    <br>
    <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 flex justify-center">
      <div class="text-center">
        <fmu-button button-type="outline" @button-click="resetPassword" class="mb-2">Link zum Zurücksetzen schicken
        </fmu-button>
        <br>
        <p class="font-thin cursor-pointer" @click="loginModalStore.togglePasswordReset()">Anmelden</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordResetModal',
  components: {},
}
</script>
<script setup>
import {useLoginModalStore} from "../../../store/modals/login-modal";
import FmuButton from "../../theme/button/FmuButton";
import {emailErrorMsg, emptyErrorMsg, mailIsValid} from "../../../validators/validators";
import {ref} from "vue";
import axios from "axios";
import {baseUrl} from "../../../configurations/configurations";
import {ElLoading} from "element-plus";

const loginModalStore = useLoginModalStore();
const errorMsg = ref('');
const successMsg = ref('');

const inputIsValid = () => {
  let email = loginModalStore.form.email;

  if (mailIsValid(email)) {
    return true;
  }

  errorMsg.value = emailErrorMsg
  return false;
}

const resetPassword = () => {
  if (inputIsValid()) {
    let loading = ElLoading.service({target: '#reset-modal'})

    axios.post(baseUrl + 'api/forgot-password', {
      email: loginModalStore.form.email
    }).then((res) => {
      errorMsg.value = ''
      successMsg.value = 'Passworterinnerung wurde gesendet!';
      loading.close()
    }).catch(() => {
      successMsg.value = ''
      errorMsg.value = 'Ein Fehler ist aufgetreten. Bitte versuche es noch einmal.'
      loading.close()
    })
  }
}
</script>

<style scoped>
</style>
