<template>
  <div class="login-modal">
    <el-dialog
        title=""
        v-model="loginModalStore.isOpen"
        width="700px"
        :fullscreen="widthIs('<', 700)"
        top="25vh"
        custom-class="relative">
      <div v-if="!loginModalStore.passwordResetOpen">
        <p class="modal-headline text-center px-24 break-normal">
          LOGIN
        </p>
        <div v-if="loginModalStore.errorMsg" class="fmu-error mt-3 mx-12">
          {{ loginModalStore.errorMsg }}
        </div>
        <div class="mt-4 mb-24 px-12">
          <form>
            <input type="text" class="fmu-input mb-2" name="email" placeholder="E-Mail-Adresse"
                   v-model="loginModalStore.form.email">
            <input type="password" name="password" class="fmu-input mb-2" placeholder="Passwort"
                   v-model="loginModalStore.form.pw" @keyup.enter="userStore.login()">
          </form>
          <div class="mt-1">
            <span class="float-left text-xs">
              <input id="remember" type="checkbox" style="vertical-align: middle" v-model="loginModalStore.form.remember">
              <label for="remember" class="ml-2">Angemeldet bleiben</label>
            </span>
            <p class="font-thin text-xs cursor-pointer float-right" @click="loginModalStore.togglePasswordReset()">
              Passwort vergessen?</p>
          </div>
        </div>
        <br>
        <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 flex justify-center">
          <fmu-button button-type="outline" @button-click="userStore.login()">Anmelden</fmu-button>
        </div>
      </div>

      <!--      PASSWORD RESET MODAL-->
      <password-reset-modal v-else></password-reset-modal>
    </el-dialog>
  </div>
</template>

<script>
import {ElDialog} from "element-plus"
import FmuButton from "@/components/theme/button/FmuButton";

export default {
  name: 'LoginModal',
  emits: ['button-click'],
  components: {FmuButton, ElDialog}
}
</script>

<script setup>
import {useLoginModalStore} from "@/store/modals/login-modal";
import {useUserStore} from "@/store/user/user-store";
import PasswordResetModal from "./PasswordResetModal";
import widthIs from "@/validators/validators";

const loginModalStore = useLoginModalStore()
const userStore = useUserStore()
</script>

<style scoped>
</style>
<style>
.login-modal > .el-overlay > .el-dialog {
  height: auto;
}
</style>
