<template>
  <div class="p-8">
    <div class="flex justify-center pb-8">
      <div class="bg-bm-primary width-5rem p-6 rounded-full text-white">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-8">
          <path fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"/>
        </svg>
      </div>
    </div>
    <div class="text-center px-12">
      <h1 class="modal-headline">Danke!</h1><br>
      <p class="text-xl font-thin">
        Wir haben Deine Bestellung erhalten! <br>
        Du erhältst eine Bestellbestätigung per eMail und deine Zugangsdaten schnellstmöglich!
      </p><br><br>
      <fmu-button @button-click="redirectHome" v-if="userStore.user.isLoggedIn">Jetzt loslegen!</fmu-button>
      <fmu-button @button-click="redirectHome" v-else>Jetzt Content ansehen!</fmu-button>
    </div>
    <br>
  </div>
</template>

<script>
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import FmuButton from "@/components/theme/button/FmuButton";
import {ref} from "vue"
import {useUserStore} from "@/store/user/user-store";

export default {
  name: 'SubBookedContent',
  components: {FmuButton},
  emits: ['button-click'],
  setup() {
    const newAboModalStore = useNewAboModalStore();
    const userStore = useUserStore();
    const agbChecked = ref(false)

    const redirectHome = () => {
      window.location.replace("/");
    }

    return {
      redirectHome,
      userStore,
      agbChecked,
      newAboModalStore
    }
  }
}
</script>

<style scoped>
.width-5rem {
  width: 5rem;
}
</style>
