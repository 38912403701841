<template>
  <div class="flex justify-center border border-t">
    <div class="absolute w-full">
      <div class="footer w-full flex md:justify-center pl-12 md:pl-0"
           :class="{'bottom-0':  ['404', 'payment_success', 'payment_error', 'payment_canceled', 'settings'].includes($route.name)}">
        <div class="grid grid-cols-1 md:grid-cols-5 pt-10 lg:mx-12 ">
          <div class="col-span-1">
            <p class="font-bold pb-3 text-black">BasicMarketing.Cloud</p>
            <router-link class="block text-base hover:text-bm-primary" :to="nav.url"
                         v-for="nav in navigationStore.menu" :key="nav">{{ nav.name }}
            </router-link>
            <br>
            <a href="https://hutter-unger.de" target="_blank" class="text-bm-primary">Hutter & Unger GmbH</a>
          </div>
          <div class="pt-6 md:pt-0 md:px-6 md:border-l border-bm-font-gray-2 col-span-3">
            <p class="font-bold pb-3 text-black">Durchsuche beliebte Kategorien...</p>
            <div class="grid grid-cols-1 md:grid-cols-3">
              <div v-if="mediaFilterStore.filter[0]">
                <a class="text-base block cursor-pointer"
                   @click="addFilter(mediaFilterStore.filter[0][3].id, filter.id)"
                   v-for="filter in mediaFilterStore.filter[0][3].options" :key="filter.id">
                  {{ filter.option }}
                </a>
              </div>
              <div class="xl:ml-16">
                <a class="text-base block cursor-pointer"
                   @click="selectTopicWorld(topicWorld)"
                   v-for="topicWorld in mediaStore.topicWorlds" :key="topicWorld.id">
                  {{ topicWorld.title }}
                </a>
              </div>
              <div class="xl:ml-16" v-if="mediaFilterStore.filter[0]">
                <a class="text-base block cursor-pointer"
                   @click="addFilter(mediaFilterStore.filter[0][2].id, filter.id)"
                   v-for="filter in mediaFilterStore.filter[0][2]?.options"
                   :key="filter.id">
                  {{ filter.option }}
                </a>
              </div>
            </div>
          </div>
          <div class="pt-6 md:pt-0 md:px-6 md:border-l border-bm-font-gray-2 col-span-1">
            <p class="font-bold pb-3 text-black">Meine Informationen</p>
            <div v-if="userStore.user.isLoggedIn">
              <router-link class="block text-base text-bm-primary" to="/einstellungen">Konto</router-link>
              <a class="block text-base text-bm-primary cursor-pointer" @click="downloads">Downloads</a>
              <a class="block text-base text-bm-primary cursor-pointer" @click="favs">Favoriten</a>
            </div>
            <div v-else>
              <a class="block text-base text-bm-primary cursor-pointer" @click="loginModalStore.toggle()">Anmelden</a>
            </div>
          </div>
        </div>
      </div>
      <div class="text-sm py-4 bg-bm-font-gray-4 mt-12 w-full h-14">
        <div class="flex justify-center">
          <div style="width: 1400px">
            <div class="text-center md:float-left pb-5">
              <router-link to="/datenschutz">Datenschutz</router-link>
              <router-link to="/impressum" class="pl-5">Impressum</router-link>
            </div>
            <div class="text-center md:float-right py-3 md:py-0">&copy; {{ moment(new Date()).format('Y') }} Hutter & Unger GmbH. Alle Rechte
              vorbehalten.
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Footer',
  components: {},
  props: {},
  setup() {
    return {
      moment
    }
  },
  methods: {}
}
</script>

<script setup>
import {useNavigationStore} from "../../../store/navigation/navigation-store";
import {useUserStore} from "../../../store/user/user-store";
import {useMediaFilterStore} from "../../../store/media/media-filter-store";
import {useRouter} from "vue-router";
import {useLoginModalStore} from "@/store/modals/login-modal";
import {useMediaStore} from "@/store/media/media-store";
import emitter from "@/services/emitter";

const navigationStore = useNavigationStore()
const userStore = useUserStore()
const mediaFilterStore = useMediaFilterStore()
const loginModalStore = useLoginModalStore();
const mediaStore = useMediaStore()

const router = useRouter()

if (mediaFilterStore.filter.length === 0) {
  mediaFilterStore.getAllFilter();
}

if (!mediaStore.topicWorlds.length) {
  mediaStore.getAllTopicWorlds();
}

const downloads = () => {
  mediaFilterStore.setPrimarySortingFilter('Downloads')
  router.push({name: 'home', hash: '#filter'})
}

const favs = () => {
  mediaFilterStore.setPrimarySortingFilter('Favoriten')
  router.push({name: 'home', hash: '#filter'})
}

const addFilter = async (filterId, optionId) => {

  await router.push({name: 'home', hash: '#filter'})

  if (!mediaFilterStore.secondaryFilterOpen) {
    mediaFilterStore.toggleSecondaryFilter();
  }

  if (!mediaFilterStore.filterModel[filterId].includes(optionId)) {
    // mediaFilterStore.forceRerenderComponent();
    mediaFilterStore.filterModel[filterId].push(optionId)
  }
}

const selectTopicWorld = (topicWorld) => {
  let searchString = 'Themenwelt: ' + topicWorld.title;

  mediaFilterStore.searchString = searchString
  emitter.emit('search-content', {search: searchString})
}
</script>

<style scoped>
</style>
