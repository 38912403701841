import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {createPinia} from 'pinia'
import LoadScript from "vue-plugin-load-script";
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from 'vue3-vt-notifications'
import Cookies from "js-cookie";
import VueGtag from "vue-gtag";

axios.defaults.baseURL = baseUrl + 'api/fmu/';

import './assets/style/tailwind/tailwind.css'
import {useUserStore} from "@/store/user/user-store";
import {baseUrl} from "@/configurations/configurations";
import vClickOutside from "click-outside-vue3"

const app = createApp(App)
const pinia = createPinia();

app.use(router)
app.use(pinia)
app.use(LoadScript);
app.use(Notifications);
app.use(VueAxios, axios)
app.use(vClickOutside)
app.use(VueGtag, {
    bootstrap: false,
    config: { id: "G-R38LFZR8JP" }
}, router)

app.mount('#app')

/**
 * if user is guest or user has no sub id => redirect
 * for specific pages (middlewareRoutes array)
 * @type {string[]}
 */
const middlewareRoutes = ['settings', 'media'];
router.beforeEach(async (to) => {
    const userStore = useUserStore(pinia);
    if (!userStore.user.isLoggedIn && Cookies.get('token')) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
        await userStore.reAuth();
    }

    if (middlewareRoutes.includes(to.name) && (!userStore.user.isLoggedIn || userStore.user.isLoggedIn && userStore.user.subscriptions.length === 0)) return '/'
})

/**
 * Meta for routing
 */
router.beforeEach((to, from, next) => {

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (nearestWithTitle) document.title = `Basic Marketing - Fashion-Content | ${nearestWithTitle.meta.title}`;

    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if (!nearestWithMeta) return next();
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-router-controlled', '');
        return tag;
    }).forEach(tag => document.head.appendChild(tag));
    next();
});
