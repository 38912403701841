import {ref} from "vue";

const sevenCharactersLock = ref(false)
const numberLock = ref(false);
const specialCharacterLock = ref(false)

export const containsSevenCharacters = ref(false)
export const containsNumber = ref(false)
export const containsSpecialCharacter = ref(false)

export const progressColor = ref('red')
export const progress = ref(0)

export const passwordValid = ref(true)

export const checkPassword = (form) => {

    const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    containsSevenCharacters.value = form.password.length >= 7;
    containsNumber.value = /\d/.test(form.password);
    containsSpecialCharacter.value = format.test(form.password)

    progressProcess(containsSevenCharacters, sevenCharactersLock);
    progressProcess(containsNumber, numberLock);
    progressProcess(containsSpecialCharacter, specialCharacterLock);

    passwordValid.value = containsSpecialCharacter.value && containsNumber.value && containsSevenCharacters.value;

    if (progress.value === 33.33) {
        progressColor.value = 'red'
    } else if (progress.value === 66.66) {
        progressColor.value = 'yellow';
    } else {
        progressColor.value = 'green';
    }

    if (form.password == '' || progress.value < 0) {
        progress.value = 0;
    }
}

const progressProcess = (contains, lock) => {
    if (contains.value && !lock.value) {
        progress.value += 33.33
        lock.value = true;
    }

    if (!contains.value && lock.value) {
        progress.value -= 33.33;
        lock.value = false;
    }
}
