<template>
  <p class="font-serif text-25px text-center px-24 pb-4 font-semibold break-normal">
    Wähle Deine Abonnement Details
  </p>
  <div class="fmu-error mx-24 mb-4" v-if="subStore.errorMsg && !newAboModalStore.freeAboAccepted">
    {{ subStore.errorMsg }}
  </div>
  <div class="grid grid-cols-2 py-5">
    <div class="border border-fmu-fontcolor rounded pt-4 text-center ml-10 mr-3">
      <div class="flex justify-center">
        <label class="font-serif text-2xl font-semibold">Monatszahlung</label>
      </div>
      <div class="px-6">
        <p class="text-sm text-center hyphens font-light pt-2 pb-4">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aspernatur corporis deleniti ea esse est ex
          exercitationem.
        </p>
        <hr class="border-1 border-fmu-fontcolor">
        <div class="flex justify-center leading-none py-3">
          <p class="py-1 pl-2">
            <b class="font-serif text-3xl">{{ newAboModalStore.sub.price_monthly }} €</b>
            <small class="font-sans font-thin"> / Monat</small>
          </p>
        </div>
        <hr class="border-1 border-fmu-fontcolor">
        <p class="text-12px text-center hyphens font-light py-4">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aspernatur corporis deleniti ea esse est ex
          exercitationem illum.
        </p>
      </div>
    </div>
    <div class="border border-fmu-fontcolor rounded pt-4 text-center mr-10 ml-3 relative">
      <!--      <span class="bg-black text-white rounded px-1 absolute right-6 top-2 leading-none py-1 font-bold"-->
      <!--            style="font-size: 8px">{{ subStore.getPercentageDiscount(newAboModalStore.sub) }} % sparen</span>-->
      <div class="flex justify-center">
        <label class="font-serif text-2xl font-semibold">Jahreszahlung</label>
      </div>
      <div class="px-6">
        <p class="text-sm text-center hyphens font-light pt-2 pb-4">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aspernatur corporis deleniti ea esse est ex
          exercitationem.
        </p>
        <hr class="border-1 border-fmu-fontcolor">
        <div class="flex justify-center leading-none py-3">
          <p class="py-1 pl-2">
            <b class="font-serif text-3xl">{{ newAboModalStore.sub.price_yearly }} €</b>
            <small class="font-sans font-thin"> / Jahr</small>
          </p>
        </div>
        <hr class="border-1 border-fmu-fontcolor">
        <p class="text-12px text-center hyphens font-light py-4">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aspernatur corporis deleniti ea esse est ex
          exercitationem illum.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import {useSubStore} from "@/store/subs/sub-store";

export default {
  name: 'ChargeableSub',
  components: {},
  setup() {
    const newAboModalStore = useNewAboModalStore();
    const subStore = useSubStore();
    return {
      subStore,
      newAboModalStore
    }
  }
}
</script>

<style scoped>
</style>
